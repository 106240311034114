/**=====================
   55. theme layout CSS Start
==========================**/

//Dark layout
body {
	.for-dark {
		display: none;
	}
	[class*="dark-"] {
		.for-light {
			display: none;
		}
		.for-dark {
			display: block;
		}
	}
	.light-only {
		.page-wrapper {
			.page-body-wrapper {
				.page-body {
					background-color: $light;
					ul.the-icons {
						li {
							color: $black;
							display: inline-block;
							padding: 10px;
							&:hover {
								background: $black;
								box-shadow: 0 0 3px $dark-card-background;
								color: $white;
							}
							em {
								display: none;
							}
						}
					}
				}
			}
		}
	}
	.dark-only {
		color: $dark-all-font-color;
		background-color: $dark-body-background;
		.text-muted {
			color: $sidebar-submenu-font-color !important;
		}
		.dropdown-item {
			color: $dark-all-font-color;
			&:hover,
			&:focus {
				background: $dark-card-background;
			}
		}
		.ProfileCard-realName {
			a,
			span {
				color: $dark-all-font-color;
			}
		}
		.ProfileCard {
			&:hover {
				color: $dark-all-font-color;
				background: $dark-card-background;
			}
		}
		.modal {
			.theme-close {
				background-color: $dark-card-background !important;
			}
			.modal-footer {
				border-top: 1px solid $dark-border-color;
			}
			.modal-header {
				.close {
					color: $dark-all-font-color;
				}
			}
			.form-control {
				background-color: $dark-body-background;
				border-color: $dark-border-color;
				color: $dark-all-font-color;
			}
		}
		.mega-inline,
		.mega-horizontal,
		.mega-vertical {
			.card {
				box-shadow: 1px 1px 7px 0 rgba($dark-card-border, 0.5) !important;
			}
		}
		.cont,
		.sub-cont {
			background-color: $dark-card-background;
		}
		.checkbox {
			label {
				&::before {
					background-color: $dark-body-background;
					border-color: $dark-card-border;
				}
			}
		}
		// masonry
		.photoswipe-pb-responsive {
			.custom-mesonry {
				> div {
					img {
						border: 1px solid $dark-card-border;
					}
				}
			}
		}
		// bookmark app
		.js-example-basic-single,
		.js-example-disabled-results {
			border-color: $dark-card-border;
			background-color: $dark-body-background;
			color: $dark-all-font-color;
		}
		// list
		.vertical-tab {
			.nav-tabs {
				.nav-item {
					border: 1px solid $dark-card-border;
					+ .nav-item {
						border-top: none;
					}
				}
				.nav-link {
					color: $dark-small-font-color;
					border-bottom: none;
					&.active {
						color: $white;
						background-color: $primary-color;
					}
					&:hover,
					&:focus {
						border-color: $dark-card-border;
					}
				}
			}
		}
		// index page css start
		.earning-card {
			&.card {
				.card-body {
					.left_side_earning {
						p {
							color: $dark-all-font-color;
						}
					}
					.earning-content {
						border-right: 1px solid $dark-card-border;
					}
					.inner-top-left,
					.inner-top-right {
						ul {
							li {
								color: $dark-all-font-color;
								&.active {
									color: $primary-color;
								}
							}
						}
					}
					.media {
						.media-body {
							p {
								color: $dark-all-font-color;
							}
						}
					}
					.border-top {
						border-top: 1px solid $dark-card-border !important;
					}
				}
			}
		}
		.chart_data_left,
		.chart_data_right {
			.card-body {
				.chart-main {
					.media {
						border-right: 1px solid $dark-card-border;
						&.border-none {
							border-right: none;
						}
					}
				}
				.media {
					.right-chart-content {
						span {
							color: $dark-all-font-color !important;
						}
					}
				}
			}
		}
		.chart_data_right {
			.card-body {
				.knob-block {
					input {
						color: $dark-all-font-color !important;
					}
				}
			}
		}
		.third-news-update,
		.news,
		.appointment-sec {
			.news-update {
				span {
					color: $dark-all-font-color;
				}
				border-bottom: 1px solid $dark-card-border;
			}
			.appointment {
				.radar-chart {
					.apexcharts-datalabel {
						fill: $dark-all-font-color;
					}
				}
			}
		}
		.chat-default {
			.card-body {
				&.chat-box {
					.input-group {
						.form-control {
							background-color: $dark-card-background !important;
							border: none !important;
							border-left: 1px solid $primary-color !important;
							&::placeholder {
								color: $dark-all-font-color;
							}
						}
					}
				}
			}
		}
		.appointment-table {
			table {
				tr {
					td {
						&.img-content-box {
							.font-roboto {
								color: $dark-all-font-color;
							}
						}
					}
				}
			}
		}
		.notification {
			.card {
				.media {
					.media-body {
						p {
							span {
								color: $dark-all-font-color;
							}
						}
						span {
							color: $dark-all-font-color;
						}
					}
				}
			}
		}
		.current-sale-container {
			svg {
				.apexcharts-graphical {
					defs {
						linearGradient {
							stop[offset="0.8"],
							stop[offset="1"] {
								stop-color: $dark-card-background;
							}
						}
					}
				}
			}
		}
		.radar-chart,
		#radarchart {
			svg {
				.apexcharts-radar-series {
					polygon {
						stroke: $dark-card-border;
						&:nth-child(odd) {
							fill: $dark-card-background;
						}
						&:nth-child(even) {
							fill: rgba($primary-color, 0.2);
						}
					}
					line {
						stroke: $dark-card-border;
					}
				}
			}
		}
		// index page css end
		// ecommerce dashboard css start
		.dash-chart {
			.media {
				.media-body {
					p {
						color: $dark-all-font-color;
						.font-roboto {
							color: $dark-all-font-color;
						}
					}
				}
			}
		}
		.our-product {
			table {
				tr {
					td {
						p {
							color: $dark-all-font-color;
						}
					}
				}
			}
		}
		.activity-timeline {
			.media {
				.activity-line {
					background-color: $light-all-font-color;
				}
				.media-body {
					p {
						color: $dark-all-font-color;
					}
				}
			}
		}
		.total-users {
			.goal-status {
				ul {
					&:last-child {
						li {
							border-top-color: $dark-card-border;
						}
					}
				}
			}
		}
		.best-seller-table {
			table {
				tbody {
					tr {
						td {
							.label {
								color: $dark-all-font-color;
							}
							p {
								color: $light-all-font-color;
							}
						}
						&:hover {
							td {
								color: $primary-color !important;
								span {
									color: $dark-all-font-color !important;
								}
								.label,
								p {
									color: $primary-color !important;
								}
							}
						}
					}
				}
			}
		}
		@each $activity-dot-name, $activity-dot-color in (primary, $primary-color), (secondary, $secondary-color),
			(success, $success-color), (danger, $danger-color), (info, $info-color), (light, $light-color),
			(dark, $dark-color), (warning, $warning-color)
		{
			.activity-dot-#{$activity-dot-name} {
				background-color: $dark-card-background;
			}
		}
		// ecommerce dashboard css end
		// timeline 1
		.timeline-content {
			background: $dark-body-background;
			&::after {
				border-color: transparent transparent transparent $dark-body-background;
			}
		}
		.timeline-item {
			&:nth-child(even) {
				.timeline-content {
					&::after {
						border-color: transparent $dark-body-background transparent transparent;
					}
				}
			}
		}
		// product page
		.product-wrapper {
			.product-sidebar {
				.filter-section {
					.card {
						.left-filter {
							background-color: $dark-card-background;
							box-shadow: 0 16px 15px 2px $dark-card-background;
						}
					}
				}
			}
		}
		.product-size {
			.btn-outline-light {
				color: $dark-all-font-color;
			}
		}
		.owl-theme {
			.owl-nav {
				[class*="owl-"] {
					background-color: $transparent-color;
				}
			}
		}
		// cart page
		.btnLess1,
		.btnLess,
		.btnGtr1,
		.btnGtr {
			background: $dark-body-background;
			color: $dark-all-font-color;
		}
		.touchspin1 {
			background: $dark-body-background;
			color: $dark-all-font-color;
		}
		// knowledgebase
		.search-form {
			input {
				border: 1px solid $dark-border-color;
				color: $dark-all-font-color;
			}
		}
		// job search
		.job-search {
			.job-description {
				.theme-form {
					.row {
						div[class^="col-"] {
							.job-select2 {
								background-color: $dark-body-background;
								border-color: $dark-border-color !important;
								color: $dark-all-font-color;
							}
						}
					}
				}
			}
		}
		// social app
		.social-app-tab {
			.hovercard {
				background-color: $dark-body-background !important;
			}
			.border-tab {
				&.tabs-scoial {
					ul {
						&.nav-tabs {
							background: $dark-card-background;
						}
					}
				}
			}
			input {
				border-color: $dark-border-color;
				color: $dark-all-font-color;
			}
		}
		// file manager
		.file-content {
			.form-inline {
				border: 1px solid $dark-card-border;
			}
			.files {
				.file-box {
					border: 1px solid $dark-card-border;
					background-color: $dark-body-background;
					.file-top {
						background-color: $dark-card-background;
						border: 1px solid $dark-card-border;
					}
				}
			}
			.folder {
				.folder-box {
					border: 1px solid $dark-card-border;
					background-color: $dark-body-background;
				}
			}
		}
		.file-sidebar {
			.pricing-plan {
				border: 1px solid $dark-card-border;
			}
		}
		// project app
		.project-box {
			border: 1px solid $dark-card-border;
			background-color: $dark-body-background;
		}
		// contact app
		.list-persons {
			.profile-mail {
				.media {
					.media-body {
						ul {
							li {
								+ li {
									border-left: 2px solid $dark-card-border;
								}
							}
						}
					}
				}
			}
		}
		.contacts-tabs {
			.nav-pills {
				border-right: 1px solid $dark-card-border;
				.nav-link {
					+ .nav-link {
						border-top: 1px solid $dark-card-border;
					}
				}
			}
		}
		.table {
			tbody {
				+ tbody {
					border-top: 2px solid $dark-border-color;
				}
			}
		}
		// calender
		.cal-month-view {
			background-color: $dark-card-background;
			.cal-cell-row {
				&:hover {
					background-color: $dark-body-background;
				}
				.cal-cell {
					&:hover,
					&.cal-has-events.cal-open {
						background-color: $dark-body-background;
					}
				}
			}
			.cal-day-cell:not(:last-child) {
				border-right-color: $dark-card-border;
			}
			.cal-days {
				border-color: $dark-card-border;
				.cal-cell-row {
					border-bottom-color: $dark-card-border;
				}
			}
			.cal-day-cell {
				&.cal-weekend {
					.cal-day-number {
						color: $danger-color;
					}
				}
			}
		}
		.cal-week-view {
			background-color: $dark-card-background;
			border-top: 1px solid $dark-card-border;
			.cal-day-headers {
				border-color: $dark-card-border;
				.cal-header {
					&:not(:last-child) {
						border-right-color: $dark-card-border;
					}
					&:first-child {
						border-left-color: $dark-card-border;
					}
				}
				.cal-header:hover,
				.cal-drag-over {
					background-color: $dark-body-background;
				}
			}
			.cal-hour {
				&:not(:last-child),
				&:last-child :not(:last-child) {
					.cal-hour-segment {
						border-bottom-color: $dark-card-border;
					}
				}
			}
			.cal-hour-odd {
				background-color: $dark-body-background;
			}
			.cal-time-events {
				border-color: $dark-card-border;
				.cal-day-columns:not(.cal-resize-active) {
					.cal-hour-segment {
						&:hover {
							background-color: $dark-body-background;
						}
					}
				}
			}
			.cal-day-column {
				border-left-color: $dark-card-border;
			}
			.cal-all-day-events {
				border-color: $dark-card-border;
			}
		}
		// ngx editor
		.ngx-toolbar {
			background-color: $dark-body-background;
			border: 1px solid $dark-border-color;
			.ngx-toolbar-set {
				background-color: $dark-card-background !important;
				.ngx-editor-button {
					border: 1px solid $dark-border-color !important;
					border-right: transparent !important;
					color: $dark-all-font-color !important;
					&:hover {
						background-color: $dark-body-background !important;
					}
				}
			}
		}
		.ngx-editor {
			.ngx-wrapper {
				.ngx-editor-textarea {
					border: 1px solid $dark-border-color !important;
				}
			}
		}
		.ngx-editor-grippie {
			background-color: $dark-body-background;
			border: 1px solid $dark-border-color;
			border-top: transparent;
		}
		// ck editor
		.ck-editor__editable {
			color: $theme-font-color;
		}
		// ngx chart
		.ngx-charts {
			.gridline-path {
				stroke: $dark-border-color;
			}
		}
		// google chart
		.gogglepie,
		.gogglearea {
			svg {
				rect {
					fill: $transparent-color;
				}
				g {
					text[x="128"],
					text[x="454"],
					text[x="636"],
					text[x="115"],
					text[y="343.05"],
					text[x="360"] {
						fill: $dark-all-font-color;
					}
				}
			}
		}
		.goggleline,
		.gogglecombo {
			svg {
				g {
					rect[y="96"],
					rect[x="201"] {
						fill: $dark-border-color;
					}
					text[x="185"],
					text[y="428.6"],
					text[x="1384"] {
						fill: $dark-all-font-color;
					}
				}
			}
		}
		.gogglecombo {
			svg {
				g {
					rect[y="96"],
					rect[x="201"] {
						fill: $dark-border-color;
					}
					text[x="76.6"],
					text[x="765.5"],
					text[x="201"] {
						fill: $dark-all-font-color;
					}
				}
			}
		}
		#area-chart1,
		#area-chart2 {
			svg {
				g {
					rect[x="131"] {
						fill: $dark-card-border;
					}
				}
			}
		}
		#bar-chart2 {
			rect[y="77"] {
				fill: $dark-card-border;
			}
		}
		// datatable
		.custom-datatable {
			input {
				border: 1px solid $dark-card-border;
				color: $light-text;
				&::placeholder {
					color: $light-text;
				}
			}
			.ngx-datatable {
				&.bootstrap {
					.datatable-header {
						border: 1px solid $dark-card-border;
						border-bottom: none;
						.datatable-header-cell {
							border-bottom: none;
						}
					}
					.datatable-body {
						border: 1px solid $dark-card-border;
						border-top: none;
						.datatable-selection {
							.datatable-scroll {
								.datatable-row-wrapper {
									&:last-child {
										border-bottom: none;
									}
								}
							}
						}

						.datatable-body-row {
							border-top: 1px solid $dark-card-border;
							&.active {
								background-color: $primary-color;
							}
							&.datatable-row-even {
								background-color: $dark-body-background;
								&:hover {
									background-color: #16171f;
								}
								&.active {
									background-color: $primary-color;
								}
							}
							&.datatable-row-odd {
								&:hover {
									background-color: #16171f;
								}
							}
							input {
								background-color: $dark-card-background;
							}
						}
					}
					.datatable-footer {
						background: $dark-card-background;
						.datatable-pager {
							ul {
								li {
									&:not(.disabled).active {
										a {
											background-color: $primary-color;
										}
									}
									&:not(.disabled):hover {
										a {
											border: 1px solid $primary-color;
										}
									}
								}
							}
						}
					}
				}
				.datatable-footer {
					.datatable-pager {
						.pager {
							border: 1px solid $dark-card-border;
						}
					}
				}
			}
		}
		// form wizard
		aw-wizard-navigation-bar {
			&.horizontal {
				&.small,
				&.large-filled-symbols {
					ul {
						&.steps-indicator {
							li:not(:last-child):after {
								background-color: $light-all-font-color;
							}
						}
					}
				}
				ul {
					&.steps-indicator {
						&.steps-3 {
							li {
								p {
									color: $dark-all-font-color;
								}
							}
						}
					}
				}
			}
			&.vertical {
				&.small {
					ul {
						&.steps-indicator {
							li:not(:last-child):after {
								background-color: $light-all-font-color;
							}
						}
					}
				}
			}
		}
		// accordion
		.accordion {
			> .card {
				border: 1px solid $dark-card-border !important;
				.card-header {
					.btn-link {
						color: $dark-all-font-color;
					}
				}
			}
		}
		// datepicker
		.custom-datepicker,
		.mutliple-datepicker {
			.ngb-dp-navigation-select {
				.custom-select {
					background-color: $white !important;
				}
			}
			.custom-select,
			.custom-file-label {
				color: $theme-body-font-color !important;
			}
			.custom-select {
				&:disabled {
					color: #6c757d !important;
					background-color: #e9ecef !important;
				}
			}
		}
		.mutliple-datepicker,
		.custom-datepicker-dropdown {
			color: $theme-body-font-color;
			> label {
				color: $dark-all-font-color;
			}
			.custom-day {
				color: $theme-body-font-color;
				&.range,
				&:hover {
					color: $white;
				}
			}
			ngb-datepicker,
			.dropdown-menu {
				background-color: $white !important;
				border: none !important;
			}
			.align-content-between {
				.custom-select {
					color: $dark-all-font-color !important;
					border: 1px solid $dark-border-color !important;
				}
			}
			hr {
				border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
			}
		}
		.custom-datepicker-dropdown {
			.form-group {
				label {
					color: $dark-all-font-color;
				}
			}
		}
		.btn-outline-dark {
			color: $dark-all-font-color;
		}
		#column-chart .apexcharts-legend-text b {
			color: unset;
		}
		#chart-widget13,
		#candlestick {
			.apexcharts-tooltip.apexcharts-theme-light {
				border: 1px solid $dark-border-color;
				background: $dark-body-background;
			}
		}
		.apexcharts-gridline {
			stroke: $dark-border-color;
		}
		.apexcharts-tooltip-title,
		.apexcharts-tooltip-series-group,
		.apexcharts-tooltip.light,
		.apexcharts-menu-item {
			color: $black;
		}
		.page-main-header {
			.translate_wrapper {
				&.active {
					.more_lang {
						background-color: $dark-card-background;
						box-shadow: 0 0 20px rgba($dark-body-background, 0.1);
						&:before {
							border-bottom: 7px solid $dark-card-background;
						}
						&:after {
							border-bottom: 7px solid $dark-card-border;
						}
					}
					.lang {
						border-bottom: 1px solid $dark-card-border;
					}
				}
			}
			.main-header-right {
				.search-full {
					background-color: $dark-body-background;
					.form-control-plaintext {
						color: $dark-all-font-color;
					}
				}
				.left-menu-header {
					ul {
						li {
							.search-bg {
								background-color: rgba($primary-color, 0.2);
								i {
									color: $primary-color;
								}
							}
							.search-form {
								input {
									color: $dark-all-font-color;
								}
							}
						}
					}
					.mega-menu {
						div {
							> div {
								a {
									color: $dark-small-font-color;
								}
							}
						}
					}
					.mega-menu-container {
						background-color: $dark-card-background;
						border-top: $dark-card-border;
						.link-section {
							li {
								&:hover {
									a {
										color: $primary-color;
									}
								}
							}
						}
					}
				}
				.nav-right {
					.chat-dropdown,
					.profile-dropdown {
						li {
							&:last-child {
								border-top: 1px solid $dark-card-border !important;
							}
						}
					}
					.cart-dropdown,
					.chat-dropdown {
						li {
							+ li {
								border-top: 1px solid $dark-card-border;
							}
						}
					}
					&.right-menu {
						ul {
							li {
								.profile-media {
									.media-body {
										p {
											color: $dark-all-font-color;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.page-wrapper.horizontal-wrapper {
			.page-main-header {
				border-bottom: 1px solid $dark-card-border;
			}
			.page-body-wrapper {
				&.horizontal-menu {
					header {
						&.main-nav {
							background: $dark-card-background;
							.main-navbar {
								.nav-menu {
									> li {
										> a {
											color: $dark-all-font-color;
											&.active {
												svg,
												span {
													color: $dark-all-font-color;
												}
											}
										}
										.nav-submenu {
											background: $dark-card-background;
											li {
												a {
													color: $dark-all-font-color;
													&.active,
													&:hover {
														color: $primary-color;
													}
												}
												.nav-sub-childmenu {
													background: $dark-card-background;
												}
											}
										}
										.mega-menu-container {
											background: $dark-card-background;
											.mega-box {
												.link-section {
													.submenu-content {
														li {
															a {
																color: $dark-all-font-color;
															}
														}
													}
												}
											}
										}
									}
									li {
										&.dropdown {
											&:hover {
												a {
													&.active {
														svg {
															stroke: $primary-color;
														}
														span {
															color: $primary-color;
														}
													}
												}
											}
										}
									}
								}
								.left-arrow,
								.right-arrow {
									background-color: $dark-card-background;
								}
							}
						}
					}
				}
			}
		}
		$alert-name: primary, secondary, success, danger, warning, info, light, dark;

		$alert-color: $primary-color, $secondary-color, $success-color, $danger-color, $warning-color, $info-color,
			$light-color, $dark-color;

		@each $var in $alert-name {
			$i: index($alert-name, $var);
			.alert-#{$var} {
				background-color: rgba(nth($alert-color, $i), 0.8) !important;
				border-color: rgba(nth($alert-color, $i), 0.9) !important;
				color: $white;
				.progress {
					height: 5px;
					background-color: darken(nth($alert-color, $i), 1%);
					border-radius: 0;
				}
				.progress-bar {
					background-color: lighten(nth($alert-color, $i), 50%);
				}
			}
			.alert-#{$var}.inverse {
				background-color: $transparent-color !important;
			}
		}
		.default-according {
			.card {
				.card-body {
					border: 1px solid $dark-card-border;
				}
			}
		}
		.options {
			> div {
				border: 1px solid $dark-body-background;
			}
		}
		.pricing-simple {
			box-shadow: 1px 1px 2px 1px $dark-body-background !important;
		}
		.pricing-block {
			box-shadow: 0 0 10px 5px rgba($primary-color, 0.05) !important;
		}
		.search-page {
			ul {
				&.search-info {
					li {
						+ li {
							border-left: 1px solid $dark-body-background;
						}
					}
				}
			}
		}
		.browse {
			.browse-articles {
				h6 {
					border-bottom: 1px solid $dark-card-border;
				}
			}
		}
		.table {
			th,
			td {
				border-top: 1px solid $dark-card-border;
			}
		}
		.job-search {
			.job-description {
				.theme-form {
					.row {
						div[class^="col-"] {
							.input-group {
								.datepicker-here {
									border: 1px solid $dark-body-background;
								}
							}
						}
					}
				}
			}
		}
		.mega-inline,
		.mega-horizontal,
		.mega-vertical {
			.card {
				border: 1px solid $dark-border-color;
			}
		}
		.sales-product-table {
			table {
				tbody {
					tr {
						td {
							border-bottom: 1px solid $dark-border-color;
						}
					}
				}
			}
		}
		.sales-product-table-footer {
			.media {
				.media-body {
					span {
						color: $dark-all-font-color;
					}
				}
			}
		}
		.dashboard-map {
			.map-right-box {
				.media {
					.media-body {
						span {
							color: $dark-all-font-color;
						}
					}
				}
			}
		}
		.knob-widgets {
			.knob-block {
				canvas {
					filter: drop-shadow(3px 2px 5px $dark-body-background);
				}
			}
			.knob-bottom-widgets {
				h6 {
					color: $dark-all-font-color;
				}
			}
			.knob-content-center {
				span {
					color: $dark-all-font-color;
				}
			}
		}
		.work-plan {
			h6,
			p {
				color: $light-text;
			}
			p {
				background-color: $dark-body-background;
			}
		}
		.setting-dot {
			.setting-bg {
				background-color: $secondary-color;
			}
		}
		.bookmark.pull-right {
			border: 1px solid darken($dark-card-background, 15%);
		}
		.progressbar-widgets {
			.media {
				.media-body {
					p {
						color: $dark-all-font-color;
					}
				}
			}
		}
		.card {
			.card-header {
				h5 {
					background: unset;
					-webkit-text-fill-color: $white;
				}
			}
		}
		.call-chat-card {
			.call-images {
				img + img {
					filter: drop-shadow(0px 10px 5px $dark-body-background);
				}
			}
		}
		.apex-chart-container {
			.apexcharts-legend {
				.apexcharts-legend-series {
					span {
						color: $dark-all-font-color !important;
					}
				}
			}
		}
		.apexcharts-canvas {
			svg {
				.apexcharts-title-text {
					fill: $white;
				}
				.apexcharts-subtitle-text {
					fill: $white;
				}
				.apexcharts-yaxis {
					.apexcharts-yaxis-texts-g {
						.apexcharts-yaxis-label {
							fill: $white;
						}
					}
				}
				.apexcharts-xaxis {
					.apexcharts-xaxis-texts-g {
						.apexcharts-xaxis-label {
							fill: $white;
						}
					}
				}
				.apexcharts-legend-series {
					span {
						color: $dark-all-font-color !important;
					}
				}
				.apexcharts-datalabels-group {
					.apexcharts-datalabel-label {
						fill: $white;
					}
					.apexcharts-datalabel-value {
						fill: $white;
					}
				}
			}
		}

		.Typeahead-menu {
			background-color: $dark-body-background;
		}
		.ecommerce-widget {
			border: 1px solid $dark-card-border !important;
		}
		.bookmark {
			ul {
				li {
					.search-form {
						.form-control-search {
							input {
								background-color: $dark-body-background;
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
							}
							&:before {
								background: $dark-border-color;
							}
						}
					}
				}
			}
		}
		.cart {
			.qty-box {
				.input-group {
					.btn {
						background-color: #2e3648 !important;
						border-color: $dark-border-color !important;
					}
				}
			}
		}
		.checkout {
			.checkout-details {
				background-color: $dark-body-background;
				border: 1px solid $dark-border-color;
				padding: 40px;
				.animate-chk {
					.radio_animated {
						&:after {
							border: 2px solid $dark-card-background;
						}
					}
				}
			}
		}
		.order-box {
			.title-box {
				color: #bfc2c6;
				border-bottom: 1px solid $dark-border-color;
			}
			.qty {
				li {
					color: #bfc2c6;
					span {
						color: #bfc2c6;
					}
				}
				border-bottom: 1px solid $dark-border-color;
			}
			.sub-total {
				li {
					color: #bfc2c6;
				}
			}
			.total {
				li {
					color: #bfc2c6;
				}
			}
		}
		.shopping-checkout-option {
			.checkbox_animated {
				&:after {
					border: 2px solid $dark-card-background;
				}
			}
		}
		.animate-chk {
			.radio_animated {
				&:after {
					border: 2px solid $dark-border-color;
				}
			}
		}
		.custom-scrollbar::-webkit-scrollbar-thumb {
			background-color: rgba(41, 50, 64, 0.52);
		}
		.card {
			.card-header {
				.card-header-right {
					background-color: $dark-card-background;
				}
			}
		}
		.product-box {
			.product-img {
				.product-hover {
					ul {
						li {
							.btn {
								color: $dark-small-font-color;
							}
							&:hover {
								.btn {
									color: $white;
								}
							}
						}
					}
				}
			}
		}
		.radio_animated,
		.checkbox_animated {
			&:after {
				background: $dark-body-background;
				border: 2px solid $dark-body-background;
			}
		}
		.touchspin1 {
			border: 1px solid $dark-card-border;
		}
		.slider-product {
			border-top: 1px solid $dark-border-color;
			border-bottom: 1px solid $dark-border-color;
		}
		.square-product-setting {
			.icon-grid {
				background-color: $dark-card-background;
				svg {
					color: $dark-all-font-color;
				}
			}
		}
		.pricing-card-design-2 {
			.pricing-block {
				.pricing-inner {
					ul {
						li {
							border-bottom: 1px solid $dark-border-color;
						}
					}
				}
			}
		}
		.flot-chart-container {
			.legend {
				.table {
					tbody {
						background-color: $dark-card-background;
						color: $white;
						.legendLabel {
							padding-left: 5px;
						}
					}
				}
			}
		}
		.google-visualization-tooltip {
			text {
				fill: rgba(0, 0, 0, 0.7) !important;
			}
		}
		.nav-dark,
		.nav-pills.nav-dark {
			.nav-link {
				color: $white;
			}
		}
		.loader-wrapper {
			background-color: $dark-body-background;
			.loader {
				background-color: $dark-body-background !important;
			}
		}
		.page-wrapper {
			/* Main Header start */
			.page-main-header {
				background-color: $dark-body-background;
				.main-header-right {
					background-color: $dark-card-background;
					li {
						i {
							color: $light-color;
						}
					}
					.nav-right {
						> ul {
							> li {
								&:before {
									background-color: $dark-body-background;
								}
								svg {
									stroke: $white;
								}
								&:last-child,
								&:nth-child(5),
								&:nth-child(1) {
									border-left: none;
								}
								&:first-child {
									.search-form {
										.form-group {
											&:before {
												background: $dark-border-color;
											}
										}
									}
								}
							}
						}
						&.right-menu {
							.language-dropdown {
								.dropdown-item {
									a {
										color: $dark-all-font-color;
									}
								}
							}
						}
						.nav-menus {
							li {
								.media {
									.media-body {
										.txt-dark {
											color: $dark-all-font-color !important;
										}
									}
								}
								.onhover-show-div {
									background-color: $dark-body-background;
									box-shadow: 0 0 14px $dark-body-background;
									p {
										color: $dark-all-font-color;
									}
									&:before {
										border-bottom: 7px solid $dark-body-background;
									}
									&:after {
										border-bottom: 7px solid $dark-body-background;
									}
								}
								.notification-dropdown {
									li {
										span {
											color: $dark-small-font-color;
										}
										&:last-child {
											border-top: 1px solid $dark-card-border;
										}
									}
									.bg-light {
										background-color: #282e38 !important;
										color: $dark-all-font-color !important;
									}
								}
							}
						}
						.profile-dropdown {
							li {
								a {
									color: $dark-all-font-color;
									svg {
										path,
										line,
										circle {
											color: $dark-all-font-color !important;
										}
									}
									&:hover {
										color: $primary-color;
										svg {
											line,
											path,
											circle {
												color: $primary-color !important;
											}
										}
									}
								}
							}
						}
						.search-form {
							input {
								background-color: $dark-body-background;
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
							}
						}
						> ul {
							> li {
								.media {
									.media-body {
										.txt-dark {
											color: $dark-all-font-color !important;
										}
									}
								}
							}
						}
					}
				}
				.main-header-left {
					background-color: $dark-card-background;
				}
				@media only screen and (max-width: 991px) {
					.main-header-right {
						.toggle-sidebar {
							border-right: 1px solid $dark-card-border;
						}
					}
				}
			}
			.right-sidebar {
				background-color: $dark-body-background;
				box-shadow: 0 0 11px rgba(69, 110, 243, 0.13);
				.modal-header {
					border-bottom: 1px solid $dark-border-color;
				}
				.friend-list-search {
					background-color: $dark-card-background;
					input {
						background-color: $dark-body-background;
						border: 1px solid $dark-border-color;
					}
					.fa {
						color: $light-all-font-color;
					}
				}
			}
			&.compact-wrapper {
				.page-body-wrapper {
					&.sidebar-icon {
						header {
							&.main-nav {
								background-color: $dark-card-background;
								.nav-menu {
									> li {
										.nav-submenu {
											li {
												a {
													&:after {
														background: $dark-card-background;
													}
												}
											}
										}
										.mega-menu-container {
											.mega-box {
												.link-section {
													.submenu-title {
														h5 {
															color: $dark-all-font-color;
															&:after {
																background-color: $dark-card-background;
															}
														}
													}
													.submenu-content {
														&.opensubmegamenu {
															li {
																a {
																	color: $dark-all-font-color;
																}
															}
														}
													}
												}
											}
											&::after {
												background-color: $light-all-font-color;
											}
										}
										.nav-link {
											&.active {
												color: $white;
											}
										}
										> a {
											color: $dark-all-font-color;
										}
									}
									&.custom-scrollbar {
										&::-webkit-scrollbar-thumb {
											background-color: $dark-card-background;
										}
									}
									.dropdown {
										ul.nav-submenu {
											li {
												a {
													color: $dark-all-font-color;
													&.active {
														color: $primary-color;
													}
												}
											}
										}
									}
								}
								.main-navbar {
									.sidebar-title {
										p {
											color: $dark-small-font-color;
										}
									}
									.nav-menu {
										& > li {
											.nav-submenu {
												&:after {
													background-color: $light-all-font-color;
												}
											}
											a {
												svg {
													color: $dark-all-font-color !important;
												}
											}
										}
									}
								}
							}
						}
						.according-menu {
							i {
								color: $dark-all-font-color;
							}
						}
					}
				}
			}
			/* Main Header ends */
			.page-body-wrapper {
				/* body start*/
				.page-body {
					.card.custom-card {
						.card-header {
							border-bottom: none !important;
						}
					}
					.search-form {
						input {
							background-color: $dark-card-background;
						}
					}
				}
				.custom-card .card-header img {
					background-color: $black;
					opacity: 0.8;
				}
				.page-header {
					.row {
						h3 {
							small {
								color: $dark-small-font-color;
							}
						}
					}
					.breadcrumb {
						li {
							color: $dark-all-font-color;
						}
					}
					.breadcrumb-item {
						&.active {
							color: $dark-small-font-color;
						}
					}
				}
				.page-body {
					background-color: $dark-body-background;
					.card {
						background-color: $dark-card-background;
						box-shadow: 1px 1px 2px 1px rgba(29, 28, 28, 0.08);
						.chart-block {
							#bar-chart2 {
								svg {
									> rect {
										fill: $dark-card-background;
									}
									> g {
										text[x="113"],
										text[y="343.05"],
										text[x="126"] {
											fill: $dark-all-font-color;
										}
									}
								}
							}
							.word-tree {
								svg {
									> g {
										> rect {
											fill: $dark-card-background;
										}
										> text {
											fill: $dark-all-font-color;
										}
									}
								}
							}
						}
						.card-header {
							background-color: $transparent-color;
							border-bottom: 1px solid $dark-card-border;
							&.card-header-border {
								.right-header {
									span {
										&:first-child {
											color: $dark-small-font-color;
										}
									}
									.right-header-dropdown.onhover-show-div {
										background-color: $dark-body-background;
										box-shadow: 0 0 2px 2px $dark-body-background;
										a {
											color: $dark-all-font-color;
										}
									}
								}
							}
							> span {
								color: $dark-all-font-color;
							}
							h1,
							h2,
							h3,
							h4,
							h5,
							h6 {
								color: $dark-all-font-color;
							}
							.card-header-right {
								i {
									color: $dark-all-font-color;
								}
							}
						}
						.alert-dark {
							color: $dark-small-font-color;
							a {
								color: $dark-small-font-color;
							}
						}
						.alert-light.outline,
						.alert-light.outline-2x,
						.alert-light.inverse {
							color: $dark-all-font-color;
						}
						#animation-box {
							.animate-widget {
								p {
									color: $dark-small-font-color !important;
								}
							}
						}
						.grid-item {
							img {
								background-color: $dark-card-background;
								border: 1px solid $dark-border-color;
							}
						}
						.line {
							color: $dark-all-font-color;
						}
						.table {
							th,
							td {
								color: $dark-all-font-color;
							}
							thead {
								th {
									border-bottom: 2px solid $dark-card-border;
								}
								.border-bottom-primary {
									th {
										border-bottom: 1px solid $primary-color;
									}
								}
							}
							.table-primary,
							.table-secondary,
							.table-success,
							.table-info,
							.table-warning,
							.table-light,
							.table-danger {
								th,
								td {
									color: $dark-card-background;
								}
							}
							.bg-primary,
							.bg-secondary,
							.bg-success,
							.bg-info,
							.bg-warning,
							.bg-danger {
								color: $white;
							}
							.bg-light {
								color: $black;
							}
							.thead-light {
								th {
									color: $black;
								}
							}
							tbody {
								.border-bottom-primary {
									th,
									td {
										border-bottom: 1px solid $primary-color;
									}
								}
							}
							.Double,
							.Dotted,
							.Dashed {
								th,
								td {
									border-color: $dark-card-border;
								}
							}
							thead {
								.Double,
								.Dotted,
								.Dashed {
									th {
										border-color: $dark-card-border !important;
									}
								}
							}
						}
						.table[class*="bg-"] {
							th,
							td {
								color: $white;
							}
						}
						.table-striped {
							tbody {
								tr {
									&:nth-of-type(odd) {
										background-color: rgba(0, 0, 0, 0.05);
										&:hover {
											th,
											td {
												color: $white;
											}
										}
									}
								}
							}
						}
						.table-bordered {
							td,
							th {
								border-color: $dark-card-border !important;
							}
						}
						.table-inverse {
							tbody {
								tr {
									&:nth-of-type(odd) {
										&:hover {
											td {
												color: $dark-all-font-color;
											}
										}
									}
								}
							}
						}
						.table-border-vertical {
							tr,
							th,
							td {
								border-right: 1px solid $dark-card-border;
							}
						}
						.table-styling {
							thead,
							tbody {
								th,
								td {
									color: $white;
								}
							}
						}
						.card-footer {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-card-border;
						}
						.switch {
							.switch-state {
								background-color: $dark-body-background;
							}
							input {
								&:checked {
									+ .switch-state {
										background-color: $primary-color;
									}
								}
							}
						}
						.bg-white {
							background-color: $white !important;
						}
						.b-l-light {
							border-left: 1px solid $dark-border-color !important;
						}
						.ct-grid {
							stroke: $dark-border-color;
						}
						.ct-label {
							color: $dark-small-font-color;
						}
						hr {
							border-top: 1px solid $dark-card-border;
						}
						.calender-widget {
							.cal-date {
								background-color: $dark-card-background;
								border: 1px solid $dark-border-color;
							}
						}
						.contact-form {
							.theme-form {
								border: 1px solid $dark-border-color;
								.form-icon {
									background-color: $dark-card-background;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}
						}
						.btn-outline-light,
						.btn-outline-dark,
						.btn-outline-light-2x {
							color: $white !important;
						}
						.btn-outline-light {
							&:hover {
								color: $black !important;
							}
						}
						.border-right {
							border-right: 1px solid $dark-card-border !important;
						}
						.flot-chart-placeholder {
							.flot-text {
								color: $dark-all-font-color !important;
							}
							svg {
								text {
									fill: $dark-all-font-color;
								}
							}
						}
						.chart-overflow {
							&#gantt_chart {
								svg {
									g {
										rect {
											&:first-child {
												fill: $dark-body-background;
											}
										}
									}
								}

								rect {
									&:nth-child(6) {
										fill: $dark-body-background;
									}
								}
							}

							&#line-chart {
								rect {
									fill: $dark-card-background;
								}
							}
							&#column-chart1,
							&#column-chart2 {
								rect[x="126"],
								rect[y="77"] {
									fill: $dark-card-border;
								}
							}
							&#combo-chart {
								rect[x="202"] {
									fill: $dark-card-border;
								}
							}
							svg {
								> rect {
									fill: $dark-card-background;
								}
								> g {
									> g {
										> g {
											text {
												fill: rgb(177, 177, 178);
											}
										}
									}
									> text {
										fill: $dark-all-font-color;
									}
								}
							}
						}
						.radial-bar {
							&:after {
								background-color: $dark-card-background;
								color: $dark-all-font-color;
							}
						}
						.bar-chart-widget {
							.bottom-content {
								.num {
									color: $dark-all-font-color;
									.color-bottom {
										color: $dark-all-font-color;
									}
								}
							}
						}
						.b-r-light {
							border-right: 1px solid $dark-border-color !important;
						}
						.chart-container {
							.live-products,
							.turnover,
							.monthly,
							.uses {
								.ct-labels {
									.ct-label {
										color: $white;
									}
								}
								.ct-grid {
									stroke: $white;
								}
							}
							#browser-uses-chart,
							#website-visiter-chart {
								svg {
									text {
										fill: $dark-all-font-color;
									}
								}
							}
						}
						.status-details {
							h4 {
								color: $dark-all-font-color;
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
						.clipboaard-container {
							.form-control {
								background-color: $dark-body-background;
								color: $dark-small-font-color;
								border: 1px solid $dark-border-color;
							}
						}
						.img-thumbnail {
							background-color: $dark-body-background;
							border: 1px solid $dark-border-color;
						}
						#example-style-3_wrapper {
							#example-style-3 {
								tfoot {
									border-top: 2px solid $dark-border-color;
								}
							}
						}
						.page-link {
							border: 1px solid $dark-body-background;
						}
						.page-item.disabled {
							.page-link {
								background-color: $dark-card-background;
								border-color: $dark-border-color;
							}
						}
						.page-link {
							color: $dark-all-font-color;
							background-color: $dark-card-background;
						}
						.page-item {
							&:hover {
								.page-link {
									background-color: $dark-body-background;
								}
							}
						}
						.page-item.active {
							.page-link {
								background-color: $dark-body-background;
							}
						}
						.theme-pagination {
							.page-item {
								.page-link {
									color: $dark-all-font-color !important;
								}
								&.active,
								&:hover {
									.page-link {
										color: $white !important;
									}
								}
							}
						}
						.ecommerce-widget {
							.icon {
								color: $dark-body-background;
							}
							.total-num {
								color: $dark-all-font-color;
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
						.flot-chart-container-small {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-border-color;
						}
						.product-table {
							#API-2_wrapper {
								#API-2 {
									tbody {
										td {
											span,
											p {
												color: $dark-small-font-color;
											}
										}
									}
								}
							}
							h6 {
								color: $dark-small-font-color;
							}
						}
						.border-tab.nav-tabs {
							border-bottom: 1px solid $dark-border-color;
							.nav-item {
								.nav-link {
									color: $dark-all-font-color;
								}
							}
							.nav-link {
								&.active,
								&:focus,
								&:hover {
									color: $primary-color;
								}
							}
						}
						.scroll-demo {
							border: 1px solid $dark-border-color;
						}
						.search-form {
							input {
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
								background-color: $dark-body-background;
							}
							.form-group {
								&:before {
									background: $dark-card-background;
								}
							}
						}
						.cd-timeline-content {
							background-color: $dark-border-color;
							&::before {
								border-left: 7px solid $dark-border-color;
							}
						}
						.cd-timeline-block {
							&:nth-child(even) {
								.cd-timeline-content {
									&::before {
										border-right-color: $dark-border-color;
										border-left: transparent;
									}
								}
							}
						}
						.breadcrumb {
							&.bg-white {
								background-color: $dark-card-background !important;
							}
						}
						.user-status {
							table {
								td,
								th {
									border-top: none !important;
								}
							}
						}
						#donut-color-chart-morris-daily,
						#donut-color-chart-morris,
						#browser-uses-chart,
						#website-visiter-chart {
							svg {
								opacity: 0.5;
							}
						}
					}
					.gallery-with-description {
						h4 {
							color: $dark-all-font-color;
						}
						a {
							> div {
								border: 1px solid $dark-card-border;
							}
						}
						p {
							color: $dark-small-font-color;
						}
					}
					.sub-title {
						border-bottom: 1px solid rgba(255, 255, 255, 0.1);
					}
					.navs-icon {
						li {
							a {
								color: $dark-all-font-color;
								&:hover {
									color: $primary-color;
								}
							}
						}
						.separator {
							border: 1px solid $dark-border-color;
						}
					}
					.default-according {
						li {
							.text-muted {
								color: $dark-all-font-color !important;
							}
						}
					}
					.navs-icon.default-according.style-1 {
						li {
							button {
								&:hover {
									color: $primary-color !important;
								}
							}
						}
					}
					.nav-list {
						.nav-list-disc {
							li {
								a {
									color: $dark-all-font-color;
								}
								&:hover {
									color: $primary-color;
									a {
										color: $primary-color;
									}
								}
							}
						}
					}
					.navs-dropdown {
						.onhover-show-div {
							background-color: $dark-body-background;
							box-shadow: 0 0 2px 2px $dark-border-color;
							.navs-icon {
								li {
									a {
										svg {
											path,
											line,
											polyline,
											polygon,
											rect {
												color: $dark-all-font-color !important;
											}
										}
										&:hover {
											svg {
												path,
												line,
												polyline,
												polygon,
												rect {
													color: $primary-color !important;
												}
											}
										}
									}
								}
							}
						}
					}
					.onhover-dropdown {
						&:hover {
							.onhover-show-div {
								&:before {
									border-bottom: 7px solid $dark-body-background;
								}
							}
						}
					}
					.default-according {
						.card {
							box-shadow: 0 0 1px 1px rgb(55, 66, 82) !important;
							.card-header {
								border: 1px solid $dark-card-border;
							}
							.btn-link {
								color: $dark-all-font-color;
							}
							.card-body {
								color: $dark-small-font-color;
								border: 1px solid $dark-body-background;
							}
						}
					}
					.border {
						border: 1px solid $dark-border-color !important;
					}
					.blog-box {
						.blog-date {
							color: $dark-all-font-color;
						}
						.blog-details,
						.blog-details-main {
							.blog-social {
								li {
									color: $dark-small-font-color;
									border-right: 1px solid $dark-card-border;
									&:first-child {
										border-right: 1px solid $dark-card-border;
									}
									&:last-child {
										border-right: none;
									}
								}
							}
							p {
								color: $dark-all-font-color;
							}
							.single-blog-content-top {
								border-top: 1px solid $dark-card-border;
								p {
									color: $dark-small-font-color;
								}
							}
						}
					}
					.comment-box {
						.media {
							h6 {
								span {
									color: $dark-small-font-color;
								}
							}
							img {
								border: 1px solid $dark-card-border;
							}
						}
						p {
							color: $dark-small-font-color;
						}
						.comment-social {
							li {
								color: $dark-small-font-color;
								&:first-child {
									border-right: 1px solid $dark-card-border;
								}
							}
						}
						hr {
							border-top: 1px solid $dark-card-border;
						}
					}
					.table-hover {
						tbody {
							tr {
								&:hover {
									background-color: $dark-body-background;
									td,
									th {
										color: $white;
									}
								}
							}
						}
					}
					ul.the-icons {
						li {
							border: 1px dotted $dark-border-color;
							color: $sidebar-submenu-font-color;
							display: inline-block;
							padding: 10px;
							&:hover {
								background: $black;
								box-shadow: 0 0 3px $dark-card-background;
							}
							em {
								display: none;
							}
						}
					}
					.crm-activity {
						> li {
							+ li {
								border-top: 1px solid $dark-border-color;
							}
							h6 {
								color: $dark-all-font-color;
							}
						}
						ul.dates {
							li {
								color: $dark-small-font-color;
								+ li {
									border-left: 1px solid $light-all-font-color;
								}
							}
						}
					}
					#donut-color-chart-morris,
					#donut-color-chart-morris-daily {
						svg {
							text {
								fill: $dark-all-font-color;
							}
						}
					}
					.custom-card {
						.card-profile {
							img {
								background-color: $dark-card-background;
							}
						}
						.profile-details {
							h6 {
								color: $dark-small-font-color;
							}
						}
						.card-social {
							li {
								a {
									color: $dark-small-font-color;
								}
							}
						}
						.card-footer {
							> div {
								h6 {
									color: $dark-small-font-color;
								}
								+ div {
									border-left: 1px solid $dark-card-border;
								}
							}
						}
					}
					.form-control {
						background-color: $dark-body-background;
						color: $dark-all-font-color;
						border: 1px solid $dark-border-color;
					}
					.checkbox,
					.radio {
						label {
							&::before {
								background-color: $dark-body-background;
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
							}
						}
					}
					.dropdown-basic {
						.dropdown {
							.dropdown-content {
								background-color: $dark-body-background;
								a {
									color: $dark-all-font-color;
									&:hover {
										background-color: $dark-card-background;
									}
								}
								.dropdown-divider {
									border: 1px solid $dark-border-color;
								}
							}
						}
					}
					.dropup-basic {
						.dropup {
							.dropup-content {
								background-color: $dark-body-background;
								a {
									color: $dark-all-font-color;
									&:hover,
									&.active {
										background-color: $dark-card-background;
									}
								}
							}
						}
					}
					.dropzone.dz-clickable {
						.dz-message {
							h6 {
								color: $dark-all-font-color;
							}
							span {
								color: $dark-small-font-color;
							}
						}
					}
					.dropzone {
						.dz-preview {
							background-color: $dark-body-background;
						}
					}
					.browser-widget {
						.media-body {
							column-rule: 1px solid $dark-border-color;
							h4 {
								color: $dark-all-font-color;
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
					}
					.email-wrap {
						.email-app-sidebar {
							.media {
								img {
									border: 2px solid $dark-body-background;
								}
							}
							.main-menu {
								> li {
									a {
										color: $dark-all-font-color;
										&:hover {
											background-color: $dark-body-background;
										}
									}
								}
							}
						}
						.email-right-aside {
							.email-body {
								.inbox {
									.media.active {
										background-color: $dark-body-background;
									}
								}
							}
						}
						.media-body {
							h6 {
								color: $dark-all-font-color;
								small {
									color: $dark-small-font-color;
									span {
										color: $dark-small-font-color;
									}
								}
							}
							p {
								color: $dark-small-font-color;
							}
						}
						.email-top {
							border-bottom: 1px solid $dark-border-color;
						}
						p {
							color: $dark-small-font-color;
						}
						.email-content {
							.email-top {
								.user-emailid:after {
									border: 1px solid $dark-border-color;
								}
							}
						}
					}
					.dropdown-menu {
						background-color: $dark-body-background;
						border: 1px solid $dark-border-color;
						a {
							color: $dark-all-font-color;
							&:hover {
								background-color: $dark-card-background;
							}
						}
					}
					.icon-lists {
						div {
							i {
								color: $dark-small-font-color;
							}
							&:hover {
								background-color: $dark-body-background;
							}
						}
					}
					.widget-joins {
						.media {
							.media-body {
								> span {
									color: $dark-small-font-color;
								}
							}
							.details {
								border-left: 1px solid $dark-border-color;
							}
						}
						&:before,
						&:after {
							background-color: $dark-border-color;
						}
					}
					.redial-social-widget {
						i {
							background-color: $dark-card-background;
						}
					}
					.social-widget-card {
						h5,
						h4 {
							color: $dark-all-font-color;
						}
						span {
							color: $dark-small-font-color;
						}
					}
					.b-b-light {
						border-bottom: 1px solid $dark-border-color !important;
					}
					.b-r-dark {
						border-right: 1px solid $white !important;
					}
					.testimonial {
						i {
							color: $dark-border-color;
						}
						p,
						h5 {
							color: $dark-all-font-color;
						}
						span {
							color: $dark-small-font-color;
						}
					}
					.grid-showcase {
						span {
							background-color: $dark-card-background;
							border: 1px solid $dark-card-border;
						}
					}
					.grid-align {
						.row {
							background-color: $dark-border-color;
							border: 1px solid $dark-card-border;
						}
					}
					.border-style {
						.card {
							box-shadow: none !important;
							border: 1px solid $dark-card-border !important;
						}
					}
					.offer-style {
						.card {
							box-shadow: none !important;
							border: 1px dashed $dark-card-border !important;
						}
					}
					.search-page {
						.info-block {
							+ .info-block {
								border-top: 1px solid $dark-border-color;
							}
						}
					}
					.card-absolute {
						.bg-primary,
						.bg-secondary {
							h5 {
								color: $white;
							}
						}
					}
					.timeline-small {
						.media {
							.timeline-round {
								&.timeline-line-1,
								&.small-line,
								&.medium-line {
									&:after {
										background-color: $dark-border-color;
									}
								}
							}
						}
					}
					/* body end*/
				}
				footer {
					p {
						color: $dark-all-font-color;
					}
					border-top: 1px solid $dark-body-background;
					background-color: $dark-card-background;
				}
				.custom-select,
				.custom-file-label {
					background: $dark-body-background;
					color: $dark-all-font-color;
				}
				.footer-fix {
					background-color: $dark-card-background;
					border-top: 1px solid $dark-border-color;
				}
			}
			.dt-button-info {
				background-color: $dark-card-background;
				border: 1px solid $dark-small-font-color;
				h2 {
					background-color: $dark-card-background;
				}
			}
			.chat-box {
				.about {
					.name {
						color: $dark-all-font-color;
					}
				}
				.chat-menu {
					border-left: 1px solid $dark-border-color;
					.nav-tabs {
						border-bottom: 1px solid $dark-border-color;
						.nav-item {
							.nav-link {
								&.active {
									color: $dark-all-font-color !important;
								}
							}
						}
					}

					.user-profile {
						.image {
							.icon-wrapper {
								background-color: $dark-card-background;
								box-shadow: 1px 1px 3px 1px $dark-body-background;
							}
							.avatar {
								img {
									border: 5px solid $dark-border-color;
								}
							}
						}
						.social-media {
							a {
								color: $dark-small-font-color;
							}
						}
						.follow {
							span {
								color: $dark-small-font-color;
							}
							.follow-num {
								color: $dark-all-font-color;
							}
						}
					}
				}
				.status {
					color: $dark-small-font-color;
					p {
						color: $dark-all-font-color !important;
					}
				}
				.chat-right-aside {
					.chat {
						.chat-header {
							border-bottom: 1px solid $dark-border-color;
							.chat-menu-icons {
								li {
									a {
										i {
											color: $dark-small-font-color;
										}
									}
								}
							}
							img {
								box-shadow: 1px 1px 4px 1px $dark-body-background;
							}
						}
						.chat-msg-box {
							.my-message {
								border: 1px solid $dark-border-color;
							}
							.message {
								color: $dark-all-font-color;
							}
							.other-message {
								background-color: $dark-body-background;
							}
						}
						.chat-message {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-border-color;
							.input-group {
								.form-control {
									background-color: $dark-card-background;
								}
							}
						}
					}
				}
			}
			pre {
				background-color: $dark-border-color;
				color: $dark-all-font-color;
			}
			.scorlled {
				background-color: $dark-body-background;
			}
			.input-group-air {
				box-shadow: 0 3px 20px 0 $dark-border-color;
			}
			.input-group-solid {
				.input-group-text,
				.form-control {
					background: $dark-body-background;
					border: 1px solid $dark-border-color;
					color: $dark-all-font-color;
				}
			}
			.semilight-bg-color,
			.header-bg-color {
				background-color: $white;
			}
			.list-group-item {
				background-color: $dark-card-background;
				border: 1px solid rgba(255, 255, 255, 0.3);
				color: $dark-all-font-color;
				&.active {
					background-color: $primary-color !important;
					border-color: $primary-color;
					color: $white;
				}
				&:hover,
				&:focus {
					z-index: 1;
					text-decoration: none;
				}
				+ .list-group-item {
					border-top-width: 0;
				}
			}
			.list-group-item-action {
				&:hover:not(.active),
				&:focus {
					background-color: $dark-body-background;
				}
			}
			.list-group-flush {
				.list-group-item {
					border-left: 0;
					border-right: 0;
					border-radius: 0;
				}
			}
			.list-group-item-primary {
				color: #004085;
				background-color: #b8daff;
			}
			.list-group-item-secondary {
				color: #383d41;
				background-color: #d6d8db;
			}
			.list-group-item-success {
				color: #155724;
				background-color: #c3e6cb;
			}
			.list-group-item-danger {
				color: #721c24;
				background-color: #f5c6cb;
			}
			.list-group-item-warning {
				color: #856404;
				background-color: #ffeeba;
			}
			.list-group-item-info {
				color: #0c5460;
				background-color: #bee5eb;
			}
			.list-group-item-light {
				color: #818182;
				background-color: #fdfdfe;
			}
			.list-group-item-dark {
				color: #1b1e21;
				background-color: #c6c8ca;
			}
			.auth-bg {
				background-blend-mode: overlay;
				background-color: rgba(54, 64, 74, 0.9);
				.authentication-box {
					.card {
						background-color: $dark-card-background;
						.theme-form {
							.form-group {
								input {
									color: $dark-all-font-color;
									background-color: $dark-body-background;
									border: 1px solid $dark-card-border;
								}
								label {
									color: $dark-all-font-color;
								}
							}
							.checkbox {
								label {
									color: $dark-all-font-color;
									&::before {
										background-color: $dark-body-background;
										border: 1px solid $dark-card-border;
									}
								}
							}
						}
					}
					h3,
					h4,
					h6 {
						color: $white;
					}
				}
			}
			.auth-bg-video {
				background-blend-mode: overlay;
				background-color: rgba(54, 64, 74, 0.7);
				.authentication-box {
					h4,
					h3 {
						color: $white;
					}
					h6 {
						color: $dark-small-font-color;
					}
					.card {
						background-color: $dark-card-background;
						.theme-form {
							.form-group {
								input[type="text"],
								input[type="password"] {
									background-color: $dark-body-background;
									border: 1px solid $dark-card-border;
									color: $dark-all-font-color;
								}
							}
							.checkbox {
								label {
									&::before {
										background-color: $dark-body-background;
										border: 1px solid $dark-card-border;
									}
								}
							}
						}
					}
				}
			}
			.error-wrapper {
				.maintenance-icons {
					li {
						i {
							color: $dark-border-color;
						}
						&:nth-child(3) {
							i {
								color: $dark-border-color;
							}
						}
					}
				}
			}
			.modal-content {
				background-color: $dark-card-background;
				.modal-header {
					border-bottom: 1px solid $dark-border-color;
					.close {
						color: $dark-small-font-color;
					}
				}
				.modal-footer {
					border-top: 1px solid $dark-border-color;
				}
			}
			.comingsoon {
				background-blend-mode: overlay;
				background-color: rgba(54, 64, 74, 0.9);
				.comingsoon-inner {
					h5 {
						color: $dark-all-font-color;
					}
					.countdown {
						border-top: 1px solid $dark-border-color;
						border-bottom: 1px solid $dark-border-color;
						.title {
							color: $dark-all-font-color;
						}
					}
					.coming-soon-bottom-link {
						a {
							color: $dark-all-font-color;
						}
					}
				}
			}
			.theme-form {
				.login-divider {
					border-top: 1px solid $dark-card-border;
					&:before {
						background: $dark-border-color;
						color: $dark-all-font-color;
					}
				}
			}
			.authentication-main {
				background-color: $dark-border-color;
				.auth-innerright {
					.reset-password-box {
						.card {
							background-color: $dark-card-background;
						}
						.reset-password-link {
							color: $dark-small-font-color;
						}
						.theme-form {
							.form-group {
								label {
									color: $dark-small-font-color;
								}
								.form-control {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border-color: $dark-card-border;
								}
							}
							.opt-box {
								background-color: $dark-border-color;
							}
						}
					}
					.authentication-box {
						h4 {
							color: $dark-all-font-color;
						}
						h6 {
							color: $dark-small-font-color;
						}
						h3 {
							color: $dark-all-font-color;
						}
						.card {
							background-color: $dark-card-background;
							.theme-form {
								.form-group {
									input[type="text"],
									input[type="password"] {
										background-color: $dark-body-background;
										border: 1px solid $dark-card-border;
										color: $dark-all-font-color;
									}
								}
								.checkbox {
									label {
										&::before {
											background-color: $dark-body-background;
											border: 1px solid $dark-card-border;
										}
									}
								}
							}
						}
					}
				}
			}
			.default-according {
				.card {
					background-color: $dark-card-background;
					.btn-link {
						background-color: $dark-card-background;
						border: 1px solid $dark-card-background;
						color: $white;
					}
					.text-muted {
						color: $dark-small-font-color !important;
					}
				}
				.bg-primary {
					.btn-link {
						background-color: $primary-color;
						border: 1px solid $primary-color;
					}
				}
				.bg-secondary {
					.btn-link {
						background-color: $secondary-color;
						border: 1px solid $secondary-color;
					}
				}
			}
			.collapse {
				.card-body {
					background-color: $dark-card-background;
				}
			}
			@media screen and (max-width: 1660px) {
				.chat-box {
					.chat-history {
						.total-time {
							h2 {
								color: $dark-all-font-color;
							}
						}
					}
				}
				.caller-img {
					img {
						opacity: 0.7;
					}
				}
			}
			@media only screen and (max-width: 1199px) {
				.chat-menu {
					border-top: 1px solid $dark-border-color;
					background-color: $dark-card-background;
				}
				.error-wrapper {
					.maintenance-icons {
						li {
							i {
								color: $dark-color;
							}
						}
					}
				}
				// header css
				.left-menu-header {
					.mega-menu-container {
						border: 1px solid $dark-card-border;
					}
				}
				.mobile-title {
					&.d-none {
						border-bottom: 1px solid $dark-card-border;
					}
				}
				// product page
				.feature-products {
					.filter-toggle {
						background-color: $dark-card-background;
					}
				}
				.product-wrapper {
					.product-sidebar {
						.filter-section {
							.card {
								.left-filter {
									background-color: $dark-card-background;
								}
							}
						}
					}
				}
				.product-sidebar {
					&.open {
						&:before,
						&:after {
							border-bottom: 7px solid $dark-card-background;
						}
					}
				}
			}
			@media only screen and (max-width: 575px) {
				.user-profile {
					.hovercard {
						.info {
							.user-designation {
								border-top: 1px solid $dark-border-color;
								border-bottom: 1px solid $dark-border-color;
							}
						}
					}
				}
				.widget-joins {
					.border-after-xs {
						&:after {
							background-color: $dark-border-color;
						}
					}
				}
				// header css
				&.compact-wrapper {
					.page-main-header {
						.main-header-right {
							.toggle-sidebar {
								border-left: none;
								border-right: 1px solid $dark-card-border;
							}
						}
					}
				}
			}
		}
		.lg-backdrop {
			background-color: $dark-body-background;
		}
		.lg-outer {
			.lg-thumb-outer {
				background-color: $dark-card-background;
			}
		}
		.drag {
			background-color: $dark-card-background;
			form {
				background: $dark-card-background;
				box-shadow: 0 0 30px $dark-border-color;
				border: 1px dotted $dark-border-color;
				input {
					background-color: $dark-body-background;
					border: 1px solid $dark-border-color;
					color: $dark-all-font-color;
				}
				.help-block {
					text-transform: capitalize;
					color: $dark-small-font-color;
				}
			}
		}
		.draggable {
			input {
				background-color: $dark-body-background;
				color: $dark-all-font-color;
				border: 1px solid $dark-border-color;
			}
			p {
				color: $dark-small-font-color;
			}
			select {
				background-color: $dark-body-background;
				color: $dark-all-font-color;
				border: 1px solid $dark-border-color;
			}
			.radio,
			.checkbox {
				label {
					&::before {
						background-color: $dark-body-background;
						border: 1px solid $dark-border-color;
					}
				}
			}
		}
		// select2 css start
		.ng-select {
			.ng-select-container,
			.ng-dropdown-panel {
				border: 1px solid $dark-border-color !important;
				background-color: $dark-body-background;
			}
			&.ng-select-opened {
				> .ng-select-container,
				.ng-dropdown-panel {
					border-color: $dark-border-color !important;
				}
			}
			.ng-dropdown-panel {
				.ng-dropdown-panel-items {
					.ng-option {
						background-color: $dark-body-background;
						color: $dark-all-font-color;
						&.ng-option-marked {
							background-color: $dark-card-background;
						}
					}
				}
			}
			.ng-select-container {
				color: $dark-all-font-color;
				.ng-value-container {
					.ng-input {
						> input {
							color: $dark-all-font-color;
						}
					}
				}
			}
			.ng-option-label {
				color: $dark-all-font-color;
			}
		}
		// select2 css end
		.theme-form {
			.form-group {
				select.form-control:not([size]):not([multiple]) {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
					border-color: $dark-card-border;
				}
				input[type="text"],
				input[type="email"],
				input[type="search"],
				input[type="password"],
				input[type="number"],
				input[type="tel"],
				input[type="date"],
				input[type="datetime-local"],
				input[type="time"],
				input[type="datetime-local"],
				input[type="month"],
				input[type="week"],
				input[type="url"],
				input[type="file"],
				select {
					border-color: $dark-card-border;
					background-color: $dark-body-background;
					color: $dark-all-font-color;
					&::-webkit-input-placeholder {
						color: $dark-small-font-color;
					}
				}
				.form-control {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
				}
				textarea {
					border-color: $dark-card-border;
				}
			}
			.form-divider {
				border-top: 1px solid $dark-card-border;
				&::before {
					background: $dark-card-background;
					color: $dark-all-font-color;
				}
			}
		}
		.CodeMirror {
			background: $dark-card-background;
			border: 1px solid $dark-small-font-color;
			color: $dark-all-font-color;
			.CodeMirror-code {
				pre {
					background: $dark-card-background;
				}
			}
		}
		.editor-toolbar {
			border-top: 1px solid $dark-small-font-color;
			border-left: 1px solid $dark-small-font-color;
			border-right: 1px solid $dark-small-font-color;
			a {
				color: $dark-all-font-color !important;
				&:hover,
				&.active {
					background: $dark-border-color;
				}
			}
			i.separator {
				border-left: 1px solid $dark-small-font-color;
				border-right: 1px solid $dark-small-font-color;
			}
		}
		.editor-toolbar.disabled-for-preview {
			a {
				&:not(.no-disable) {
					background: $dark-border-color;
				}
			}
		}
		.editor-preview {
			background-color: $dark-card-background;
		}
		.editor-toolbar.fullscreen,
		.editor-preview-side {
			background: $dark-card-background;
			border: 1px solid $dark-small-font-color;
		}
		.u-step {
			background: $dark-border-color;
			&.active,
			&.current {
				background: $primary-color;
				color: $white;
			}
		}
		.u-step-title,
		.u-pearl-title {
			color: $dark-all-font-color;
		}
		.u-step-number {
			background-color: $dark-card-background;
		}
		.u-pearl {
			&:before {
				background-color: $dark-border-color;
			}
		}
		.u-pearl-number,
		.u-pearl-icon {
			background: $dark-body-background;
			border: 2px solid $dark-body-background;
		}
		.u-pearl.disabled {
			.u-pearl-icon,
			.u-pearl-number {
				background: $dark-border-color;
				border: 2px solid $dark-border-color;
			}
			&:after {
				background-color: #334053;
			}
		}
		.u-pearl.error {
			&:after {
				background-color: $dark-body-background;
			}
		}
		.note-editor.note-frame {
			border-color: $dark-border-color;
			.note-editing-area {
				.note-editable {
					background-color: $dark-card-background;
					color: $dark-all-font-color;
				}
			}
		}
		.swal-modal {
			background-color: $dark-card-background;
			.swal-title {
				color: $dark-all-font-color;
			}
			.swal-text {
				color: $dark-small-font-color;
			}
			.swal-content__input {
				background-color: $dark-body-background;
				color: $dark-all-font-color;
			}
		}
		.nav-tabs {
			border-bottom: 1px solid $dark-border-color;
			.nav-link {
				&.active {
					background-color: $dark-card-background;
					color: $dark-all-font-color;
					border-color: $dark-border-color $dark-border-color $dark-card-background;
				}
				&:hover,
				&:focus {
					border-color: $dark-border-color $dark-border-color $dark-border-color;
				}
			}
			.nav-item.show {
				.nav-link {
					background-color: $dark-card-background;
					color: $dark-all-font-color;
					border-color: $dark-border-color $dark-border-color $dark-card-background;
				}
			}
		}
		.nav-tabs.nav-bottom {
			.nav-item {
				.nav-link {
					&.active,
					&.focus,
					&.hover {
						border-color: $dark-card-background $dark-border-color $dark-border-color;
					}
				}
			}
		}
		.border-tab.nav-left,
		.border-tab.nav-right {
			.nav-link {
				color: $dark-all-font-color;
				&.active {
					color: $primary-color;
				}
			}
			.show {
				> .nav-link {
					color: $primary-color;
				}
			}
		}
		.border-tab.nav-left.nav-secondary {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					border-left-color: $secondary-color;
					color: $secondary-color !important;
				}
			}
			.show > .nav-link {
				border-left-color: $secondary-color;
				color: $secondary-color !important;
			}
			.nav-item.show {
				color: $secondary-color !important;
				border-left-color: $secondary-color;
			}
		}
		.border-tab.nav-left.nav-success {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					color: $success-color !important;
				}
			}
			.show > .nav-link {
				border-left-color: $success-color;
				color: $success-color !important;
			}
			.nav-item.show {
				color: $success-color !important;
				border-left-color: $success-color;
			}
		}
		.border-tab.nav-right.nav-info {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					color: $info-color !important;
				}
			}
			.show > .nav-link {
				border-left-color: $info-color;
				color: $info-color !important;
			}
			.nav-item.show {
				color: $info-color !important;
				border-left-color: $info-color;
			}
		}
		.border-tab.nav-secondary {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					color: $secondary-color !important;
				}
			}
			.nav-item {
				&.show {
					color: $secondary-color !important;
				}
			}
		}
		.border-tab.nav-success {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					color: $success-color !important;
				}
			}
			.nav-item {
				&.show {
					color: $success-color !important;
				}
			}
		}
		.dropdown-divider {
			border-top: 1px solid $dark-border-color;
		}
		.icon-hover-bottom {
			background-color: $dark-card-background;
			box-shadow: 0px 0px 1px 1px $dark-border-color;
			.icon-title {
				color: $dark-all-font-color;
			}
			span {
				color: $dark-small-font-color;
			}
			.form-group {
				input {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
					border: 1px solid $dark-card-border;
				}
			}
			.icon-first {
				i {
					color: $dark-small-font-color;
				}
			}
		}
		code {
			background-color: $dark-body-background;
			border-radius: 2px;
		}
		#cd-timeline {
			&::before {
				background: $dark-border-color;
			}
		}
		.timeliny {
			border-top: 1px solid $dark-border-color;
			border-bottom: 1px solid $dark-border-color;
			&::before {
				background-color: $white;
			}
			.timeliny-dot {
				background-color: $dark-card-background;
				border: 1px solid $white;
				&::before {
					color: $dark-all-font-color;
				}
			}
			.timeliny-timeline {
				.timeliny-timeblock {
					&.active {
						.timeliny-dot {
							&::before {
								color: $dark-all-font-color;
							}
						}
					}
					&:not(.inactive):not(.active) {
						.timeliny-dot {
							&:hover {
								background-color: $white;
								&::before {
									color: $dark-all-font-color;
								}
							}
						}
					}
					.timeliny-dot {
						&:hover {
							&::after {
								background-color: $dark-card-background;
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
							}
						}
					}
				}
			}
		}
		.todo {
			.todo-list-wrapper {
				#todo-list {
					li {
						.task-container {
							background: $dark-card-background;
							border-bottom: 1px solid $dark-border-color;
							.task-label {
								color: $dark-all-font-color;
							}
							&:hover {
								h4 {
									color: $white;
								}
							}
							.task-action-btn {
								.action-box {
									&:hover:not(.active) {
										background: $dark-card-background;
										border: 1px solid $dark-border-color;
									}
								}
							}
						}
						&.completed {
							.task-container {
								.task-label {
									color: $primary-color;
								}
								.complete-btn {
									&:hover {
										border: 1px solid $success-color !important;
									}
								}
							}
						}
					}
				}
				.todo-list-footer {
					.new-task-wrapper {
						textarea {
							background-color: $dark-card-background;
							border: 1px solid $dark-border-color;
							color: $dark-all-font-color;
						}
					}
				}
			}
		}
		.user-profile {
			.ttl-info {
				h6 {
					color: $dark-small-font-color;
				}
				span {
					color: $dark-all-font-color;
				}
			}
			.hovercard {
				.info {
					.title {
						a {
							color: $dark-all-font-color;
						}
					}
				}
				.user-image {
					.avatar {
						img {
							border: 10px solid $dark-card-background;
						}
					}
					.icon-wrapper {
						background-color: $dark-card-background;
					}
				}
				.tabs-scoial {
					border-bottom: none !important;
				}
			}
			.follow {
				.follow-num {
					color: $dark-all-font-color;
				}
			}
			.profile-img-style {
				.user-name {
					color: $dark-all-font-color;
				}
				p {
					color: $dark-small-font-color;
				}
			}
		}
		span.twitter-typeahead {
			.tt-menu {
				background-color: $dark-body-background;
				color: $dark-all-font-color;
			}
			.tt-suggestion {
				color: $dark-all-font-color;
				&:hover,
				&:focus {
					background-color: $dark-card-background;
				}
			}
		}
		.typography {
			small {
				color: $dark-all-font-color;
			}
		}
		.blockquote-footer {
			color: $dark-small-font-color;
		}
		.code-box-copy {
			pre {
				background-color: $dark-body-background;
				code {
					background-color: $dark-body-background;
				}
			}
			pre[class*="language-"] {
				border: 1px solid $dark-border-color;
			}
			.code-box-copy__btn {
				background-color: $dark-body-background;
				border: 1px solid $dark-border-color;
				color: $white;
			}
			code[class*="language-"],
			pre[class*="language-"] {
				text-shadow: 0px 1px $black;
				::selection {
					text-shadow: none;
					background: $dark-card-background;
				}
			}
		}
		.dt-button-info {
			background-color: $dark-card-background;
			border: 1px solid $dark-border-color;
			h2 {
				background-color: $dark-card-background;
				border-bottom: 1px solid $dark-border-color;
			}
		}
		.error-wrapper {
			background-color: rgba(54, 64, 74, 0.8);
			.sub-content {
				color: $dark-all-font-color;
			}
		}
		.b-light {
			border: 1px solid $dark-border-color !important;
		}
		.modal-content {
			background-color: $dark-card-background;
			.modal-header {
				border-bottom: 1px solid $dark-border-color;
			}
			.ui-front {
				.form-control {
					background-color: $dark-card-background;
					color: $dark-all-font-color;
					border: 1px solid $dark-border-color;
				}
			}
		}
		.modal {
			.theme-close {
				background-color: $dark-card-background !important;
			}
		}
		.token.atrule,
		.token.attr-value,
		.token.keyword {
			color: $info-color;
		}
		.token {
			&.boolean,
			&.constant,
			&.deleted,
			&.number,
			&.property,
			&.symbol,
			&.tag {
				color: $danger-color;
			}
		}
		.loader-box {
			&.card-loader {
				background-color: $dark-card-background;
			}
		}
		.my-gallery {
			&.gallery-with-description {
				img {
					border: 1px solid $dark-card-border !important;
					border-bottom: none !important;
				}
			}
		}
		@each $var in $alert-name {
			$i: index($alert-name, $var);
			.alert-#{$var}.inverse {
				color: $dark-all-font-color;
			}
		}
		.alert-theme {
			span {
				+ span {
					+ span {
						border-left: 5px solid $primary-color;
						background-color: $dark-body-background;
						border-radius: 4px;
					}
				}
			}
			i {
				color: $white;
				padding: 20px;
				margin-right: 20px !important;
			}
		}
		.user-card {
			.user-deatils {
				h6 {
					color: $dark-small-font-color;
				}
			}
			.card-footer {
				> div {
					&:first-child {
						border-right: 1px solid $dark-border-color;
					}
				}
				.user-footer {
					h6 {
						color: $dark-small-font-color;
					}
					svg {
						path,
						rect {
							color: $dark-small-font-color;
						}
					}
				}
			}
		}
		.order-history {
			table {
				tr {
					td,
					th {
						border-top: none !important;
					}
				}
			}
			.title-orders {
				background-color: $dark-body-background;
			}
		}
		.navigation-option {
			ul {
				li {
					a {
						color: $dark-all-font-color;
					}
					&:hover {
						background-color: $dark-body-background;
					}
				}
			}
		}
		.product-box {
			.product-details {
				h6 {
					color: $dark-all-font-color;
				}
				span {
					color: $dark-small-font-color;
				}
			}
			.product-img {
				.product-hover {
					ul {
						li {
							background-color: $dark-card-background;
							&:hover {
								background-color: $black;
								color: $white;
							}
						}
					}
				}
			}
		}
		.browse {
			.browse-articles {
				ul {
					li {
						a {
							color: $dark-all-font-color;
						}
						&:hover {
							background-color: $dark-body-background;
						}
					}
				}
			}
		}
		@each $btn-name, $btn-color in (primary, $primary-color), (secondary, $secondary-color),
			(success, $success-color), (danger, $danger-color), (info, $info-color), (light, $light-color),
			(dark, $dark-color), (warning, $warning-color)
		{
			.radio-#{$btn-name} {
				input[type="radio"] {
					& + label {
						&::before {
							border-color: $btn-color !important;
						}
						&::after {
							background-color: $btn-color;
						}
					}
					&:checked {
						& + label {
							&::before {
								border-color: $btn-color !important;
							}
							&::after {
								background-color: $btn-color;
							}
						}
					}
				}
			}
		}

		@each $btn-name, $btn-color in (primary, $primary-color), (secondary, $secondary-color),
			(success, $success-color), (danger, $danger-color), (info, $info-color), (light, $light-color),
			(dark, $dark-color), (warning, $warning-color)
		{
			.checkbox-#{$btn-name} {
				label {
					&::before {
						border-color: $btn-color !important;
					}
				}
			}
		}

		@each $btn-name, $btn-color in (primary, $primary-color), (secondary, $secondary-color),
			(success, $success-color), (danger, $danger-color), (info, $info-color), (light, $light-color),
			(dark, $dark-color), (warning, $warning-color)
		{
			.checkbox-solid-#{$btn-name} {
				label {
					&:before {
						background-color: $btn-color !important;
						border-color: $btn-color !important;
						color: $white !important;
					}
				}
				input[type="checkbox"] {
					&:checked {
						& + label {
							&::before {
								background-color: $btn-color !important;
								border-color: $btn-color !important;
								color: $white !important;
							}
							&::after {
								color: $white !important;
							}
						}
					}
				}
			}
		}
		.bootstrap-datetimepicker-widget {
			ul {
				li {
					color: $dark-all-font-color;
					border-bottom: 1px solid $dark-border-color;
				}
			}
			tr {
				th,
				td {
					&:hover {
						background-color: $dark-card-background;
					}
					span {
						&:hover {
							background-color: $dark-card-background;
						}
					}
					&.prev,
					&.next {
						&:hover {
							background-color: $dark-card-background;
						}
					}
				}
				&:first-child {
					th {
						&:hover {
							background-color: $dark-card-background;
						}
					}
				}
			}
		}
		.btn-transparent {
			color: $dark-all-font-color;
		}
		#cal-basic {
			.fc-toolbar {
				.fc-left,
				.fc-right {
					.fc-next-button {
						.fc-icon-right-single-arrow {
							&:after {
								color: $dark-small-font-color;
							}
						}
					}
					.fc-prev-button {
						.fc-icon-left-single-arrow {
							&:after {
								color: $dark-small-font-color;
							}
						}
					}
				}
				.fc-left {
					.fc-button-group {
						.fc-month-button {
							color: $white !important;
						}
					}
				}
			}
		}
		.fc-button-group {
			.fc-basicWeek-button,
			.fc-basicDay-button,
			.fc-agendaWeek-button,
			.fc-agendaDay-button {
				color: $black !important;
			}
			.fc-month-button {
				color: $white !important;
			}
		}
		#cal-agenda-view {
			.fc-toolbar {
				.fc-right {
					.fc-button-group {
						.fc-month-button,
						.btn-light {
							color: $black !important;
						}
						.fc-agendaWeek-button {
							color: $white !important;
							&:hover {
								color: $black !important;
							}
						}
					}
				}
			}
		}
		.basic-calendar,
		#cal-bg-events,
		#cal-event-colors {
			.fc-toolbar {
				.fc-right {
					.fc-button-group {
						.fc-basicWeek-button,
						.btn-light {
							color: $black !important;
						}
						.fc-month-button {
							color: $white !important;
							&:hover {
								color: $black !important;
							}
						}
					}
				}
			}
		}
		.categories {
			ul {
				li {
					a {
						color: $dark-small-font-color;
					}
					&:hover {
						background-color: $dark-body-background;
					}
				}
			}
		}
		.socialprofile {
			.likes-profile {
				h5 {
					span {
						color: $dark-all-font-color;
					}
				}
			}
			span {
				color: $dark-small-font-color;
			}
		}
		.social-status {
			.media {
				.media-body {
					span {
						+ span {
							color: $dark-small-font-color;
						}
					}
					p,
					.light-span {
						color: $dark-small-font-color;
					}
				}
			}
		}
		.filter-cards-view,
		.timeline-content {
			p {
				color: $dark-small-font-color;
			}
			.comment-number {
				i {
					color: $dark-small-font-color;
				}
			}
			.comments-box {
				.input-group {
					.form-control {
						border: none !important;
					}
					.input-group-append {
						background-color: $dark-body-background;
					}
				}
			}
		}
		.social-chat {
			.media-body {
				border: 1px solid $dark-border-color;
				&:after {
					border-right: 7px solid $dark-card-background;
				}
			}
			span {
				span {
					color: $dark-small-font-color;
				}
			}
		}
		.details-about {
			.your-details {
				p {
					color: $dark-small-font-color;
				}
			}
		}
		.activity-log {
			.my-activity {
				p {
					color: $dark-small-font-color;
				}
			}
		}
		.bookmark {
			ul {
				li {
					a {
						color: $dark-all-font-color;
					}
				}
			}
		}
		//index page
		@media screen and (max-device-width: 1366px) {
			.earning-card {
				&.card {
					.card-body {
						.earning-content {
							border-bottom: 1px solid $dark-card-border;
						}
					}
				}
			}
		}
		@media only screen and (max-width: 767px) {
			.chart_data_left {
				.card-body {
					.chart-main {
						> div {
							&:first-child {
								border-right: 1px solid $dark-card-border;
							}
							&:nth-child(3) {
								border-right: 1px solid $dark-card-border;
							}
						}
					}
				}
			}
			// header css
			.page-wrapper {
				&.compact-wrapper {
					.left-menu-header {
						.mega-menu {
							svg {
								stroke: $dark-all-font-color;
							}
							.nav-link {
								border-left: 1px solid $dark-card-border;
							}
						}
					}
				}
			}
		}
	}

	.dark-sidebar {
		.page-main-header {
			.logo-wrapper {
				.for-light {
					display: block;
				}
				.for-dark {
					display: none;
				}
			}
		}
		.page-wrapper {
			.sidebar-title {
				p {
					color: $dark-small-font-color;
				}
			}
			&.compact-wrapper {
				.page-body-wrapper {
					&.sidebar-icon {
						header {
							&.main-nav {
								background-color: $dark-card-background;
								.toggle-sidebar {
									color: $dark-all-font-color;
								}
								.nav-menu {
									> li {
										.nav-submenu {
											li {
												a {
													&:after {
														background: $dark-card-background;
													}
												}
											}
										}
										.mega-menu-container {
											.mega-box {
												.link-section {
													.submenu-title {
														h5 {
															color: $dark-all-font-color;
															&:after {
																background-color: $dark-card-background;
															}
														}
													}
													.submenu-content {
														&.opensubmegamenu {
															li {
																a {
																	color: $dark-all-font-color;
																}
															}
														}
													}
												}
											}
											&::after {
												background-color: $light-all-font-color;
											}
										}
										.nav-link {
											&.active {
												color: $white;
											}
										}
										> a {
											color: $dark-all-font-color;
										}
									}
									&.custom-scrollbar {
										&::-webkit-scrollbar-thumb {
											background-color: $dark-card-background;
										}
									}
									.dropdown {
										ul.nav-submenu {
											li {
												a {
													color: $dark-all-font-color;
													&.active {
														color: $primary-color;
													}
												}
											}
										}
									}
								}
								.main-navbar {
									.nav-menu {
										& > li {
											.nav-submenu {
												&:after {
													background-color: $light-all-font-color;
												}
											}
											a {
												svg {
													color: $dark-all-font-color !important;
												}
											}
										}
									}
								}
							}
						}
						.according-menu {
							i {
								color: $dark-all-font-color;
							}
						}
					}
				}
			}
			&.horizontal-wrapper {
				.page-body-wrapper {
					&.horizontal-menu {
						header {
							&.main-nav {
								background: $dark-card-background;
								.main-navbar {
									.nav-menu {
										> li {
											> a {
												color: $dark-all-font-color;
												&.active {
													svg,
													span {
														color: $dark-all-font-color;
													}
												}
											}
											a {
												svg {
													color: $dark-all-font-color !important;
												}
											}
											.nav-submenu {
												background: $dark-card-background;
												li {
													a {
														color: $dark-all-font-color;
														&.active,
														&:hover {
															color: $primary-color;
														}
													}
													.nav-sub-childmenu {
														background: $dark-card-background;
													}
												}
											}
											.mega-menu-container {
												background: $dark-card-background;
												.mega-box {
													.link-section {
														.submenu-content {
															li {
																a {
																	color: $dark-all-font-color;
																}
															}
														}
													}
												}
											}
										}
										li {
											&.dropdown {
												&:hover {
													a {
														&.active {
															svg {
																stroke: $primary-color;
															}
															span {
																color: $primary-color;
															}
														}
													}
												}
											}
										}
									}
									.left-arrow,
									.right-arrow {
										background-color: $dark-card-background;
										color: $dark-all-font-color;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1199px) {
	.dark-only .page-wrapper .page-main-header .main-header-right .nav-right > .nav-menus-left.open {
		background-color: #292f3c;
		box-shadow: 0 2px 2px 2px #242933;
	}
}

/**=====================
   55. theme layout CSS ends
==========================**/
