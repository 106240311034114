/*  
  01. General CSS
  02. Generic CSS
  03. Card CSS
  04. Loader CSS
  05. Header CSS
  06. Button CSS
  07. Color CSS
  08. GRID CSS
  09. Error-page  CSS
  10. Icon css
   13. Email Application  CSS
   14. Chat CSS
   15. Alert CSS
   16. Popover CSS
   17. List CSS
   18. Switch  CSS
   19. Mega option CSS
   20. Touchspin  CSS
    21. Select 2 CSS
	22. User profile css
	23. Progress CSS
	24. According CSS
	25. Authentication CSS
	26. Form CSS
	27. Coming Soon CSS
	28. Radio CSS
	29. Form Input CSS
	30. Data-table CSS
	 32. Page CSS
	 33. Ecommerce  CSS
	 34. Pricing CSS
	 36. NAV CSS
	 37. Dropdown CSS
	 39. Table  CSS
	 41. Chart CSS
	 42. Timeline CSS
	 43. Timeline 2 CSS
	 44. Tabs CSS
	 45. Gallery CSS
	 46. Blog CSS
	 47. Footer CSS
	 48. Form Wizard
	 49. Badge CSS
	 51. Search CSS
	 52. Datepicker CSS
	 53. Dashboard CSS
	 54. Tour  CSS
	 55. theme layout CSS
	 56. Ribbon CSS
	 57. breadcrumb CSS
	 58. Steps  CSS
	 59. Customizer CSS
	 60. RTL CSS
	 61. base_inputs CSS
	 62. Responsive css
	 63. Box-layout css
	 66. Calendar CSS
	 70. Dashboard sass
	 73. Faq CSS
	 74. Job CSS
	 75. knowledgebase CSS
	 77. Learning CSS
	 78. Order History css
	 79. Print CSS
	 80. Sidebar CSS
	 81. social app
	 82. avatars
	 83. Bookmark
     84. cart
	 85. wishlist
	 86. checkout
 */
@import "icons/whether-icon/whether-icon";
@import "icons/flag-icon/flag-icon-base";
@import "icons/flag-icon/flag-icon-more";
@import "icons/flag-icon/flag-icon-list";
@import "icons/icoicon/icons";
@import "icons/font-awesome/variables";
@import "icons/font-awesome/mixins";
@import "icons/font-awesome/path";
@import "icons/font-awesome/core";
@import "icons/font-awesome/larger";
@import "icons/font-awesome/fixed-width";
@import "icons/font-awesome/list";
@import "icons/font-awesome/bordered-pulled";
@import "icons/font-awesome/animated";
@import "icons/font-awesome/rotated-flipped";
@import "icons/font-awesome/stacked";
@import "icons/font-awesome/icons";
@import "icons/font-awesome/screen-reader";
@import "icons/themify/themify-icons";
@import "icons/simple-line-icon/simple-line-icon";
@import "icons/material-design-icon/material-design-icon";
@import "icons/pe7-icon/pe7-icon";
@import "icons/typicon-icon/typicon-icon";
@import "icons/ionic-icon/ionic-icon";
@import "icons/whether-icon/whether-icon";

@import "theme/variables";
@import "theme/general";
@import "theme/generic";
@import "theme/animate";
@import "theme/card";
@import "theme/loader";
@import "theme/header";
@import "theme/sidebar";
@import "theme/buttons";
@import "theme/color";
@import "theme/grid";
@import "theme/errorpage";
@import "theme/icons";
@import "theme/email-application";
@import "theme/chat";
@import "theme/alert";
@import "theme/popover";
@import "theme/list";
@import "theme/switch";
@import "theme/megaoption";
@import "theme/touchspin";
@import "theme/select2";
@import "theme/user-profile";
@import "theme/progress";
@import "theme/according";
@import "theme/login";
@import "theme/forms";
@import "theme/comingsoon";
@import "theme/radio";
@import "theme/form-input";
@import "theme/page";
@import "theme/ecommerce";
@import "theme/pricing";
@import "theme/navs";
@import "theme/dropdown";
@import "theme/table";
@import "theme/chart";
@import "theme/timeline-v";
@import "theme/timeliny";
@import "theme/tab";
@import "theme/gallery";
@import "theme/blog";
@import "theme/footer";
@import "theme/form-wizard";
@import "theme/badge";
@import "theme/search";
@import "theme/datepicker";
@import "theme/tour";
@import "theme/custom-theme-options";
@import "theme/ribbon";
@import "theme/breadcrumb";
@import "theme/steps";
@import "theme/faq";
@import "theme/knowledgebase";
@import "theme/document";
@import "theme/job-search";
@import "theme/learning";
@import "theme/order-history";
@import "theme/social-app";
@import "theme/avatars";
@import "theme/bookmark";
@import "theme/bookmark-app";

@import "theme/file";
@import "theme/projectlist";
@import "theme/jkanban";
@import "theme/kanban";
@import "theme/language";

@import "theme/task";
@import "theme/rating";
@import "theme/cart";
@import "theme/calendar";
@import "theme/wishlist";
@import "theme/checkout";
@import "theme/angular-plugin";
@import "theme/datatable";
@import "theme/dashboard_default";
@import "theme/dashboard_2";
@import "theme/todo";
@import "theme/typeahead-search";
@import "theme/theme-customizer";
@import "theme/responsive";
@import "theme/box-layout";
@import "theme/rtl";
@import "theme/contacts";
@import "scrollable";
@import "dropzone";
@import "sticky";
