/**=====================
   59. Customizer  CSS Start
==========================**/
.customizer-links {
  position: fixed;
  right: 00px;
  top: 50%;
  z-index: 3;
  transform: translate(0, -50%);
  box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);

  >.nav {
    border-radius: 8px;
    padding: 10px;
    background-color: $white;
  }

  &.open {
    right: 330px !important;
    border-radius: 8px 0 0 8px !important;
    transition: all 0.3s ease !important;
  }
  &.open1 {
    right: 330px !important;
    border-radius: 8px 0 0 8px !important;
    transition: all 0.3s ease !important;
  }
  &.open2 {
    right: 330px;
    border-radius: 8px 0 0 8px;
    transition: all 0.3s ease;
  }
  &.open3 {
    right: 330px;
    border-radius: 8px 0 0 8px;
    transition: all 0.3s ease;
  }
  &.open4 {
    right: 330px;
    border-radius: 8px 0 0 8px;
    transition: all 0.3s ease;
  }
  .nav-link {
    padding: 0;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: rgba(115, 102, 255, 0.1);
      border-radius: 5px;
      transform: scale(0);
      transition: all 0.3s ease;
    }

    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      top: 12px;
      border-width: 5px 0 5px 5px;
      border-left-color: lighten($primary-color, 25%);
      opacity: 0;
      transform: scale(0);
      color: #fff;
      left: -10px;
      z-index: 2;
      transition: all 0.3s ease;
    }

    &:hover {
      i {
        color: var(--theme-deafult);
      }

      &:before,
      &:after {
        opacity: 1;
        transform: scale(1);
        transition: all 0.3s ease;
      }

      span {
        opacity: 1;
        right: 48px;
        transform: scale(1);
        transition: all 0.3s ease;
        color: #378365 !important;
      }
    }

    &+.nav-link {
      margin-bottom: 5px;
    }

    span {
      position: absolute;
      right: -60px;
      width: max-content;
      text-align: right;
      padding: 7px 10px;
      display: block;
      top: 0;
      border-radius: 5px;
      transform: scale(0);
      transition: all 0.3s ease;
      opacity: 0;
      color: var(--theme-deafult);
      background-color: lighten($primary-color, 25%);
      box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
    }

    i {
      font-size: 17px;
      padding: 8px 10px;
      display: inline-block;
      cursor: pointer;
      margin: 0;
      color: gray;
      border-radius: 5px;
    }
  }
}


.customizer-contain{
  direction: ltr;
  position: fixed;
  height: calc(100vh);
  top: 82px;
  width: 333px;
  right: -335px;
  background-color: $white;
  z-index: 7;
  font-family: $font-work-sans;
  transition: all 0.3s ease;
  &.open{
    right: 0px;
    transition: all 0.3s ease;
    box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);
    margin-top: 40px;
  }
  li{
    position: relative;
    &.active{
      &:before{
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 2;
        left: 0;
        top: 0;
        font-size: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $dark-color;
        box-shadow: 0 0 11px 5px rgba(226, 226, 226, 0.5);
        border-radius: 10px;
      }
    }
  }
  h6{
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: #212529;
  }
  .customizer-header{
    padding:15px 25px;
    border-bottom: 1px solid $light-color;
    .icon-close{
      position: absolute;
      font-size: 18px;
      cursor: pointer;
      font-weight: 900;
      right: 30px;
      color: #000000;
    }
    h5{
      font-weight: 700;
      text-transform: uppercase;
      color: #313131;
    }
    p{
      color: #313131;
    }
  }
  .customizer-body{
    padding : 15px 10px;
    max-height: calc(70vh - 178px);
    overflow-y: scroll;
    .layout-grid{
      &.customizer-mix{
        .color-layout{
          height: 70px;
          width: 80px;
          border: 1px solid #f6f7fb;
          border-radius: 5px;
          display: inline-block;
          margin-bottom: 7px;
          margin-right: 3px;

        }
      }
    }
    .tab-pane{
      > h6{
        position: relative;
        margin-top: 15px;
        padding-top: 15px;
        margin-bottom: 10px;
        font-size: 15px;
        &:first-child{
          border-top: 0;
          padding-top: 0;
          margin-top: 0;
        }
      }
    }
    .nac-pills{
      border: 1px solid #f1f1f1;
      margin-bottom: 10px;
      border-radius: 5px;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      .nav-item{
        width: 33.33%;
        text-align: center;
        .nav-link{
          border-radius: 5px;
          &.active{
            background-color: $primary-color;
            color: $white;
          }
        }
      }
      .nav-link{
        padding: 0.2rem 1rem;
      }
    }
    li{
      cursor: pointer;
      opacity: 0.9;
      &.active{
        opacity: 1;
      }
    }
    .fade{
      &:not(.show) {
        display: none;
      }
    }

    .color-layout{
      height: 35px;
      width: 35px;
      border-radius: 5px;
      display: inline-block;
      margin-right: 3px;
      border: 1px solid #b8b8b8;
      padding: 3px;
      > div{
        background-color: #7366ff;
        height: 100%;
        width: 100%;
        border-radius: 5px;
      }
      .body{
        .contain{
          position: absolute;
          height: auto;
          width: 100%;
          li{
            height: 1px;
            width: 65%;
            background-color: #7366ff;
            border-radius: 5px;
            padding: 0;
            margin-left: 10px;
            margin-bottom: 4px;
            &:before{
              content: "";
              position: absolute;
              height: 1px;
              width: 3px;
              background-color: #7366ff;
              left: -6px;
              border-radius: 5px;
            }
          }
        }
      }
      &[data-attr="color-2"] {
        div{
          background-color: #4831D4;
        }
      }
      &[data-attr="color-3"] {
        div {
          background-color: #d64dcf;
        }
      }
      &[data-attr="color-4"] {
        div {
          background-color: #4c2fbf;
        }
      }
      &[data-attr="color-5"]{
        div {
          background-color: #7c4dff;
        }
      }
      &[data-attr="color-6"] {
        div {
          background-color: #3949ab;
        }
      }
    }
    > li {
      .body{
        .body{
          ul{
            background-color: #f6f7fb;
          }
        }
      }
    }
    .dark{
      > li {
        background-color: #2f3c4e;
        .body{
          ul{
            background-color: #2f3c4e;
          }
        }
      }
    }
    .main-layout{
      > li{
        height: 70px;
        width: 86px;
        border: 1px solid $light-color;
        border-radius: 10px;
        display: inline-block;
        &+li{
          margin-left: 7px;
        }
      }
      .box-layout {
        padding-left: 10px;
        padding-right: 10px;
        .body {
          .badge {
            left: -14px;
          }
        }
      }
    }
    .sidebar-type,.sidebar-setting{
      > li{
        height: 70px;
        width: 86px;
        border: 1px solid $light-color;
        border-radius: 5px;
        display: inline-block;
        &+li{
          margin-left: 7px;
        }
      }
    }
    .sidebar-type {
      li {
        &:first-child {
          .body {
            ul {
              li {
                &.bg-dark, &.sidebar {
                  width: 100%;
                  height: 10px;
                  display: block;
                }
                &.bg-light, &.body {
                  height: calc(100% - 10px);
                }
              }
            }
          }
        }
      }
    }
    .sidebar-bg-settings{
      display: flex;
      align-items: center;
      justify-content: space-between;
      li{
        height: 100px;
        width: 100%;
        border: 1px solid $light-color;
        border-radius: 5px;
        display: inline-block;
        margin-right: 9px;
        box-shadow: 0 0 11px -2px rgba(128, 128, 128, 0.40);
      }
    }
  }
  .customizer-footer{
    position: absolute;
    bottom: 0;
    padding: 25px;
    width: 100%;
    border-top: 1px solid $light-color;
    background-color: $white;
    text-align: center;
  }
}
.layout-grid:not(.customizer-color){

  li{
    padding: 5px;
    > .header{
      height: 9px;
      border-radius: 3px;
      position: relative;
      ul{
        position: absolute;
        top: -8px;
        left: 5px;
        >li{
          display: inline-block;
          width: 4px;
          height: 4px;
          border-radius: 5px;
          top: calc(50% - 3px);
          background-color: $primary-color;
          margin-right: 1px;
          padding: 0;
          &:nth-child(2){
            background-color: $secondary-color;
          }
          &:nth-child(3){
            background-color: $success-color;
          }
        }
      }
    }
    > .body{
      background-color: $light-color;
      height: calc(100% - 16px);
      border-radius: 3px;
      position: relative;
      margin-top: 5px;
      text-align: center;
      line-height: 3.1;
      ul{
        height: 100%;
        background-color: $white;
        .sidebar{
          width: 18px;
          height: 100%;
          border-radius: 3px;
          display: inline-block;
          margin-right: 3px;
          padding: 0;
          &.compact{
            width: 12px;
            &~.body {
              width: calc(100% - 19px);
            }
          }
          &.compact-icon{
            width: 7px;
            &~.body {
              width: calc(100% - 14px);
            }
          }
        }
        .body{
          width: calc(100% - 25px);
          padding: 0;
          margin: 0;
          height: 100%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          .badge{
            position: absolute;
          }
        }
      }
    }
  }
}

/**=====================
     59.Customizer CSS Ends
==========================**/
