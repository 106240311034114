/**=====================
    44. Tabs CSS Start
==========================**/
.nav-tabs,
.nav-pills {
  margin-bottom: 30px;
}
.tab-content {
  .tab-pane {
    &.active {
      animation: fadeIn 0.5s ease-out;
    }
  }
}
.nav-pills {
  .nav-link.active,
  .show > .nav-link {
    background-color: $primary-color;
  }
}
.nav {
  i {
    margin-right: 10px;
  }
}

$nav-name: theme, primary, secondary, success, danger, info, light, dark, warning;
$nav-color: $primary-color, $primary-color, $secondary-color, $success-color, $danger-color, $info-color, $light-color,
  $dark-color, $warning-color;
@each $var in $nav-name {
  $i: index($label-name, $var);
  .nav-#{$var},
  .nav-pills.nav-#{$var} {
    .nav-link {
      color: nth($nav-color, $i);
    }
    .nav-link.active,
    .show > .nav-link {
      background-color: nth($nav-color, $i);
      color: $white;
    }
  }
}
.nav-right {
  justify-content: flex-end;
}
.nav-tabs.nav-bottom {
  .nav-item {
    .nav-link.active,
    .nav-link.focus,
    .nav-link.hover {
      border-color: $white #dee2e6 #dee2e6;
    }
  }
}
.tab-content {
  ~ .nav-tabs {
    &.border-tab {
      margin-bottom: 0;
      margin-top: 30px;
    }
  }
}
// Material style css
.border-tab.nav-tabs {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 30px;
  .nav-item {
    .nav-link {
      font-size: 16px;
      color: $dark-color;
      text-align: center;
      padding: 10px 25px;
      border: none;
      border-bottom: 2px solid $transparent-color;
      transition: 0.3s;
      &.active,
      &.show,
      &:focus,
      &:hover {
        color: $primary-color;
      }
      &.active,
      &.show,
      &:focus {
        border-bottom: 2px solid $primary-color;
      }
    }
  }
}
.border-tab.nav-left {
  .nav-link {
    border-left: 2px solid transparent;
    transition: all 0.3s ease;
    color: $dark-color;
    border-bottom: none;
  }
  .nav-link.active,
  .show > .nav-link {
    background-color: transparent;
    border-left-color: $primary-color;
    color: $primary-color;
    border-radius: 0;
    transition: all 0.3s ease;
  }
}
.border-tab.nav-right {
  .nav-link {
    border-right: 2px solid transparent;
    transition: all 0.3s ease;
    color: $dark-color;
    text-align: right;
    border-bottom: none;
  }
  .nav-link.active,
  .show > .nav-link {
    background-color: transparent;
    border-right-color: $primary-color;
    color: $primary-color;
    border-radius: 0;
    transition: all 0.3s ease;
  }
}
$m-nav-name: theme, primary, secondary, success, danger, info, light, dark, warning;
$nav-color: $primary-color, $primary-color, $secondary-color, $success-color, $danger-color, $info-color, $light-color,
  $dark-color, $warning-color;
@each $var in $m-nav-name {
  $i: index($label-name, $var);
  .border-tab.nav-#{$var} {
    .nav-link {
      background-color: $transparent-color;
    }
    .nav-link.active,
    .nav-item.show,
    .nav-link:focus,
    .nav-link:hover {
      color: nth($nav-color, $i);
    }
    .nav-item {
      .nav-link {
        transition: 0.3s;
        &.active,
        &.show,
        &:focus {
          border-bottom: 2px solid nth($nav-color, $i);
        }
        &.active,
        &.show,
        &:focus,
        &:hover {
          color: nth($nav-color, $i);
        }
      }
    }
  }
  .border-tab.nav-left.nav-#{$var} {
    .nav-link {
      border-bottom: none !important;
    }
    .nav-link.active,
    .show > .nav-link {
      border-left-color: nth($nav-color, $i);
      color: nth($nav-color, $i);
    }
  }
  .border-tab.nav-right.nav-#{$var} {
    .nav-link {
      border-bottom: none !important;
    }
    .nav-link.active,
    .show > .nav-link {
      border-right-color: nth($nav-color, $i);
      color: nth($nav-color, $i);
    }
  }
}
.tabbed-card {
  ul {
    position: absolute;
    right: 15px;
    top: 33px;
    &.border-tab {
      &.nav-tabs {
        top: 7px;
        border-bottom: none !important;
        .nav-item {
          float: right;
          .nav-link {
            padding: 35px 15px;
          }
        }
      }
    }
  }
}

/**=====================
     44. Tabs CSS Ends
==========================**/
