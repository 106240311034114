/**=====================
    63. Box-layout CSS start
==========================**/
[main-theme-layout="box-layout"] {
  @media (min-width: 1280px) {
    background-image: url(../../images/other-images/boxbg.jpg);
    background-blend-mode: overlay;
    background-color: rgba(255, 255, 255, 0.5);
    .calendar-sec {
      order: unset;
    }
    .chart_data_right.second {
      display: none !important;
    }
    .sass-top-cards {
      > div {
        flex: 0 0 33.33%;
        width: 33.33%;
        &:nth-child(4),
        &:nth-child(5) {
          flex: 0 0 50%;
          width: 50%;
        }
      }
    }
    .email-wrap {
      .email-right-aside {
        .email-body {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }
        .radius-left {
          border-top-left-radius: 20px !important;
          border-bottom-left-radius: 20px !important;
          border-radius: 20px;
        }
      }
      .row {
        .col-xl-3 {
          + .col-xl-3 {
            padding-right: 15px;
          }
        }
        .col-xl-6 {
          padding-left: 15px;
        }
      }
    }
    .page-wrapper {
      &.horizontal_sidebar {
        .page-body-wrapper {
          .page-sidebar {
            width: 1280px;
            .sidebar-menu {
              .right-arrow {
                left: calc(270px + (1248 - 270) * ((100vw - 320px) / (1920 - 320)));
              }
            }
          }
        }
      }
      &.compact-wrapper {
        margin-top: 40px;
        .page-body-wrapper {
          &.sidebar-icon {
            header {
              &.main-nav {
                .toggle-sidebar {
                  display: none;
                }
                &.close_icon {
                  width: 0px;
                  transform: translate(0px);
                  z-index: 0;
                  display: none;
                  visibility: hidden;
                  transition: 0.5s;
                  ~ footer {
                    padding-left: 15px;
                    margin-left: auto;
                    width: 1280px;
                  }
                  ~ .footer-fix {
                    margin-left: auto;
                    width: 1280px;
                  }
                  ~ .page-body {
                    margin-left: 0px;
                  }
                }
              }
            }
            .page-body {
              margin-bottom: 50px;
            }
          }
        }
        .page-main-header {
          &.close_icon {
            margin-left: auto;
            padding-left: 0;
            transition: $sidebar-transition;
          }
        }
      }
    }
    .page-wrapper.horizontal-wrapper {
      .page-body-wrapper {
        footer {
          width: 100%;
          margin-left: 0;
        }
        &.horizontal-menu {
          header {
            &.main-nav {
              width: 1280px;
              nav {
                overflow: hidden;
              }
              .main-navbar {
                #mainnav {
                  width: 1280px;
                  left: 0;
                  // right: 0;
                  // margin: 0 auto;
                  // position: relative;
                }
              }
            }
          }
        }
      }
      .page-main-header {
        padding-left: 0;
         .main-header-right {
           .toggle-sidebar {
             display: none;
           }
         }
      }
    }
    .page-wrapper {
      .bookmark {
        ul {
          margin-right: -1px;
        }
      }
      &.compact-wrapper {
        .footer-fix {
          width: 1280px !important;
          margin: 0 auto !important;
        }
      }
      &.horizontal_sidebar {
        .page-main-header {
          padding-left: 0;
        }
      }
      .btn-group-showcase {
        .btn-radio {
          .btn-group {
            .radio {
              input[type="radio"] {
                display: none;
              }
            }
          }
        }
      }
      .alert {
        &.inverse {
          p {
            max-width: 238px;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .page-body-wrapper {
        width: 1280px;
        box-shadow: 1px 3px 25px rgba(0, 0, 0, 0.07);
        .learning-comment {
          margin-left: -14px !important;
          float: left !important;
        }
        .todo {
          .todo-list-wrapper {
            #todo-list {
              li {
                .task-responsive {
                  min-width: 1087px;
                  overflow: auto;
                }
              }
            }
          }
        }
        margin: 0 auto;
        footer {
          width: 1280px;
          margin: 0 auto;
          right: 0;
          padding-right: 15px;
          padding-left: 280px;
          position: fixed;
          left: 0;
        }
        .footer-fix {
          margin-left: 576px;
        }
        .chat-box {
          .chat-history {
            .total-time {
              h2 {
                font-size: 28px;
                color: #717171;
              }
            }
          }
        }
        canvas {
          &#myLineCharts {
            width: 100%;
          }
        }
        .chat-right-aside {
          flex: 0 0 60%;
          max-width: 60%;
          overflow: hidden;
        }
        .caller-img {
          position: absolute;
          width: 100%;
          max-width: 100%;
          left: 15px;
          img {
            opacity: 0.7;
          }
        }
        .browser-widget {
          img {
            height: 65px;
          }
        }
        .custom-card {
          .card-header {
            img {
              margin-top: -73px;
            }
          }
          .card-profile {
            img {
              height: 130px;
              top: -17px;
            }
          }
        }
        .select2 {
          width: 901.781px;
        }
      }
      .page-main-header {
        max-width: 1280px;
        padding-left: 280px;
        margin: 0 auto;
        left: 0;
        right: 0;
        transition: $sidebar-transition;
        width: auto;
        .left-menu-header {
          margin-left: 40px;
        }
        .main-header-right {
          .toggle-sidebar {
            display: inline-block;
            left: 30px;
          }
          width: 100%;
          margin: 0;
          .nav-right {
            > ul {
              > li {
                padding: 0 15px;
              }
            }
            .dropdown {
              .dropdown-toggle {
                &::after {
                  display: none;
                }
              }
            }
          }
        }
        &.open {
          padding-left: 0;
          transition: $sidebar-transition;
        }
      }
      .pricing-wrapper-card {
        padding: 50px 20px;
      }
      .card {
        .blog-box {
          &.blog-grid {
            &.set-min-height {
              min-height: 400px;
            }
          }
        }
      }
      .flot-chart-placeholder {
        &#donut-color-chart-morris-daily {
          min-height: 430px;
        }
      }
      .flot-chart-placeholder {
        &#donut-color-chart-morris {
          min-height: 430px;
        }
      }
      .box-col-12 {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .box-col-6 {
        flex: 0 0 50%;
        max-width: 50%;
      }
      .box-col-3 {
        flex: 0 0 25%;
        max-width: 25%;
      }
      .box-col-7 {
        flex: 0 0 60%;
        max-width: 60%;
      }
      .box-col-5 {
        flex: 0 0 40%;
        max-width: 40%;
      }
      .box-col-8 {
        flex: 0 0 70%;
        max-width: 70%;
      }
      .box-col-4 {
        flex: 0 0 30%;
        max-width: 30%;
      }
      .box-col-4a {
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }
      .box-col-8a {
        flex: 0 0 66.67%;
        max-width: 66.67%;
      }
      .chat-box {
        .chat-right-aside {
          .chat {
            .chat-header {
              .chat-menu-icons {
                li {
                  a {
                    i {
                      font-size: 19px;
                    }
                  }
                }
              }
            }
          }
          flex: 0 0 100%;
          max-width: calc(100% - 15px);
          overflow: hidden;
        }
        .toogle-bar {
          display: inline-block;
          margin-right: 0 !important;
        }
      }
      .chat-menu {
        right: 0;
        border-top: 1px solid #ddd;
        opacity: 0;
        transform: translateY(-30px);
        visibility: hidden;
        top: 81px;
        position: absolute;
        z-index: 9;
        background-color: #fff;
        transition: all linear 0.3s;
      }
      .chat-menu.show {
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
        transition: all linear 0.3s;
        padding-bottom: 25px;
      }
      .ct-10.total-chart {
        .ct-chart-bar {
          .ct-series {
            .ct-bar {
              stroke-width: 23px !important;
            }
          }
        }
      }
      .email-wrap {
        .email-body {
          .email-compose {
            .cke_contents {
              &.cke_reset {
                max-height: 165px;
              }
            }
          }
        }
        .email-right-aside {
          .email-body {
            .inbox {
              height: 644px;
            }
          }
        }
        .email-content {
          .email-top {
            .user-emailid {
              &:after {
                right: -10px;
              }
            }
          }
        }
      }
      .todo {
        .notification-popup {
          right: 320px;
        }
      }
      .touchspin {
        padding: 0 10px;
      }
      .comingsoon {
        video {
          min-width: 67%;
          width: 67%;
        }
      }
      .auth-bg-effect {
        .second-effect {
          left: 55%;
        }
      }
      .auth-bg-video {
        video {
          min-width: 67%;
          width: 67%;
        }
      }
    }
    .pricing-content {
      > div {
        + div {
          + div {
            .pricing-simple {
              margin-top: 30px;
            }
          }
        }
      }
    }
    // ecommerce dashboard
    .selling-slide {
      align-items: center;
    }
    // google chart
    .goggleline,
    .gogglecombo {
      > div {
        > div {
          > div {
            width: 500px !important;
            svg {
              width: 500px;
              g {
                rect[x="1346"],
                rect[x="201"] {
                  width: 500px;
                }
              }
            }
          }
        }
      }
    }
    // checkout
    .cart {
      .qty-box {
        width: 80%;
      }
    }
    // product page
    .product-filter {
      &.new-products {
        .product-box {
          > div {
            flex: 0 0 100%;
            max-width: 100%;
          }
          .product-img {
            img {
              width: 100px;
              margin: 0 auto;
            }
          }
          .product-details {
            padding: 15px;
            text-align: center !important;
          }
        }
      }
      &.new-products {
        .owl-theme {
          .owl-nav {
            display: none;
          }
        }
      }
    }
  }
}
.rtl {
  @media (min-width: 1280px) {
    .box-layout.page-wrapper {
      .page-main-header {
        max-width: 1280px;
        padding-right: 250px;
        padding-left: unset;
        &.open {
          max-width: 1280px;
        }
        .main-header-right {
          .nav-right {
            > ul {
              > li {
                &:first-child {
                  .search-form {
                    .form-group {
                      &:after {
                        right: 22px;
                        left: unset;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .page-body-wrapper {
        footer {
          margin-right: 566px;
          margin-left: unset;
          padding-left: 15px;
          p {
            i {
              margin-right: 5px;
              margin-left: unset;
            }
          }
        }
      }
    }
  }
}
/**=====================
    63. Box-layout CSS ends
==========================**/
