/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
@import '~ng-wizard/themes/ng_wizard.min.css';
@import '~ng-wizard/themes/ng_wizard_theme_arrows.min.css';
// @import "./assets/css/dbstyle/index.css";
// @import "./assets/css/dbstyle/diagrambuilder.css";
// @import "./assets/css/dbstyle/index.css";
// @import "./assets/css/dbstyle/diagrambuilder.css";

body {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: #263032;
    // color: #3F434A;
    line-height: 1.5;

}

.router-wrapper {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: calc(100% - 47px);
    perspective: 1200px;
    transform-style: preserve-3d;
}

:host {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}



.control-label {
    font-size: 12px !important;
    color: #263032 !important;
}

.page-body {
    padding: 0 6px !important;
}

.form-group {
    font-size: 12px !important;
}

input::-webkit-input-placeholder {
    font-size: 12px !important;
}

::placeholder {
    font-size: 12px !important;
}

.page-title {
    font-size: 16px;
    font-weight: 600;
    color: #263032 !important;
}

.Title-button {
    font-size: 12px;
    font-weight: 600;
    color: #000080;
    border: 1px solid #000080;
    
}

.Title-button:hover {
    color: #000080;
    background-color: #fff;
    // color: #fff;
    // background-color: #0079C0;
}
.delete-button {
    font-size: 12px;
    font-weight: 600;
    color: red;
    border: 1px solid red;
    
}

.delete-button:hover {
    color: red;
    background-color: #fff;
    // color: #fff;
    // background-color: #0079C0;
}

.card {
    border-radius: 6px;
}

.form-control {
    border-radius: 6px !important;
    border: 1px solid #efefef;
    color: #263032 !important;
    font-size: 12px !important;
}

.modal-header {
    padding: 20px 30px 20px 30px !important;
    border-bottom: 1px solid #00000017 !important;
}

.modal-body {
    padding: 0px 30px 30px 30px;

}

.modal-title {
    font-weight: 600;
    font-size: 18px !important;
}

.modal-close {
    font-size: 15px;
    border-radius: 7px;
    background: #c4c4c457;
    color: #263032 !important;
    padding: 2px 7px 2px 7px;
    position: relative;
}

.custom-badge-success {
    cursor: pointer;
    background: #E0F7F2;
    padding: 6px 10px;
    color: #03C395;
}

.custom-badge-danger {
    cursor: pointer;
    background: #FBE9E9;
    padding: 6px 10px;
    color: #E24B4B;
}

.custom-badge-complete {
    cursor: pointer;
    background: #edfbe9;
    padding: 6px 10px;
    color: green;
}

.ngx-datatable.bootstrap {
    box-shadow: none;
    font-size: 12px;
}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell .datatable-header-cell-label {
    line-height: 24px !important;
    color: #263032 !important;
    font-weight: 600 !important;
    font-size: 12px;

}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell {
    padding: 0.75rem;
    text-align: left;
    vertical-align: top;
    color: #263032 !important;
    font-size: 12px;
}

.custom-datatable .ngx-datatable .datatable-body-row,
.custom-datatable .ngx-datatable .datatable-row-center,
.custom-datatable .ngx-datatable .datatable-header-inner {
    display: flex !important;
}

.verical-centre {
    align-self: center;
}

.modal-content {
    border-radius: 6px !important;
}

.btncolor {
    background-color: #000080;
    color: #fff;
    font-size: 15px;
    border: 1px solid #000080;
    box-shadow: 0 3 1 0.2rem rgba(0, 0, 0, 0.2) !important;
    border-radius: 4px;
}

.btncolor:hover {
    background-color: #040476;
    color: #fff;
    border: 1px solid #040476;
    border-radius: 4px;
    font-size: 15px;
}

.btncancel-outline {
    background-color: #ffffff;
    color: #263032;
    border: 1px solid #E8E9EB;
    border-radius: 6px;
    font-size: 15px;
}

.btncancel-outline:hover {
    background-color: #263032;
    color: #fff;
    border: 1px solid #E8E9EB;
    border-radius: 6px;
    font-size: 15px;
}

.nav-primary .nav-link.active,
.nav-primary .show>.nav-link,
.nav-pills.nav-primary .nav-link.active,
.nav-pills.nav-primary .show>.nav-link {
    color: #000080;
    font-size: 12px;
    background-color: transparent;
    border-radius: 15px;
    font-weight: 600;
}

.nav-primary .nav-link,
.nav-pills.nav-primary .nav-link {
    color: #8a9099;
    font-size: 12px;
    font-weight: 600;

}

.nav-tabs {
    border-bottom: none !important;
    background-color: white;
    border-radius: 15px;

}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #000080;
    background-color: #fff;
    border-color: transparent;
    border-radius: 15px;
    font-weight: 600;

}

.main-card {
    padding: 20px !important;
}

.headings {
    color: #8A9099;
    font-size: 14px !important;
    font-weight: 600;
    margin-bottom: 0px;
    white-space: nowrap;
}


.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: #7d858c;
    font-size: 12px !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: black !important;
    font-size: 13px !important;
}
//  .ng-select.ng-select-single .ng-select-container 
//  {
// height: 45px;
//  }

.nav-tabs .nav-link:hover {
    border-color: transparent !important;
}

.title-icon {
    width: 23px;
    height: 23px;
    background-color: transparent !important;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0px !important;
}

.card {
    margin-bottom: 10px;
}

.control-label {
    font-size: 12px;
}

::ng-deep.modal-content {
    border-radius: 5px !important;
}

::ng-deep.modal-backdrop.show {
    opacity: 1 !important;
}

::ng-deep.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    // width: 100vw;
    // height: 100vh;
    background-color: #eee;

}

::ng-deep.modal-open .modal {
    background-color: #eee;
}

.customer_sub {
    font-size: 14px !important;
    font-weight: 600 !important;
    // text-transform: uppercase;
    color: #263032;
}

.table td {
    color: #263032;
}

.ngx-pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #000080 !important;
    color: #fefefe;
    cursor: default;
}

.edit {
    font-size: 13px;
    border-radius: 7px;
    color: #000080 !important;
    padding: 3px 6px 3px 6px;
    position: relative;
}

.btn-delete {
    font-size: 13px;
    border-radius: 7px;
    color: red !important;
    padding: 4px 8px 4px 8px;
    position: relative;
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 128, 0.2) !important;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #0055ff4d !important;
}

.download {
    font-size: 13px;
    border-radius: 7px;
    color: #000080 !important;
    padding: 3px 6px 3px 6px;
    position: relative;
}

.btncolors {
    background-color: #fff;
    color: #000080;
    border: 1px solid #000080;
    border-radius: 6px;
    font-size: 15px !important;
}

.btncolors:hover {
    background-color: #000080;
    color: #fff;
    border: 1px solid #000080;
    border-radius: 6px;
    font-size: 15px !important;
}

.first-color {
    color: #000080;
}

.first-color:hover {
    color: #000080;
}

.date {
    display: inline-block;
    color: #000 !important;
    padding: 10px;
    position: absolute;
    top: 1px !important;
    right: 2px !important;
    z-index: 3 !important;
    font-size: 11px;
    background-color: #fff !important;
    border-radius: 20px;
    cursor: pointer;
}


.cdk-overlay-container {
    // position: fixed;
    z-index: 20000 !important;
}

::ng-deep.dropdown-list .no-data h5 {
    font-size: 13px !important;
}

::ng-deep .dropdown-list .no-filtered-data h5 {
    font-size: 13px !important;
}

.f-12 {
    font-size: 12px;
}

//Search-box-style


#custom-search-form .search-query {
    //display: block;
    width: 293px;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #efefef;
    border-radius: 20px;
}



.search-query:focus+button {
    z-index: 3;
}

.search-query:focus {
    outline-color: rgba(0, 0, 0, 0.06) !important;
}

#custom-search-form {
    margin: 0;
    margin-top: 5px;
    padding: 0;
    position: relative;
}

#custom-search-form button {
    font-size: 12px;
    border-radius: 15px;
    background: #00008012;
    color: #000080 !important;
    padding: 4px 6px 2px 6px;
    position: absolute;
    top: 3px;
    left: 263px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
}

.text-danger {
    color: #dc3545 !important;
    font-size: 12px !important;
}

.asterisk {
    color: red;
    margin-left: 3px;
}

.img-fluid {
    width: 100%;
}

.btn-black {
    color: #fff;
    background-color: #000000;
    font-size: 15px;
    box-shadow: 0px 2px 0px 0px #00000036;
    border-radius: 4px;
    padding: 10px;
}

.btn-black:hover {
    color: #d1d1d1;
    background-color: #000000;
    font-size: 15px;
    box-shadow: 0 3 1 0.2rem rgba(0, 0, 0, 0.2) !important;
    border-radius: 4px;
}

.btnnew {
    background-color: #0057FF;
    color: #ffffff;
    border: 1px solid #0057FF;
    border-radius: 4px;
    font-size: 15px !important;
    padding: 10px;
    box-shadow: 0px 2px 0px 0px #00000036;


}

.btnnew:hover {
    background-color: #004fec;
    color: #fff;
    border: 1px solid #004fec;
    border-radius: 4px;
    // font-size: 12px !important;
    padding: 10px;

}

.page-body {
    padding: 0px 6px !important;
}